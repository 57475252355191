import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
class Header extends React.Component {
  render() {
    const {
      title,
      referrer,
      showCancelButton,
      redirectUrl,
      redirectUrlFallback,
      mimo,
      oneTimePayment,
      reconnect
    } = this.props;
    const { programName } = oneTimePayment;

    let mimoRedirectURl = redirectUrl.includes('startstop')
      ? referrer
      : `${referrer}commercial/core-mimo/`;

    return (
      <header>
        <div className="container-fluid headernav-container payment-center-container">
          <div className="dte-azure-bkg" id="header-main">
            <div className="navbar navbar-default" role="navigation">
              <div className="row">
                <div
                  id="header-title"
                  className={`${
                    showCancelButton !== false
                      ? 'hidden-xs col-xs-9'
                      : 'hidden-xs col-xs-12'
                  } col-sm-5`}
                >
                  <h1>{title}</h1>
                </div>
                <div id="icon-title" className="logo hidden-xs col-sm-2">
                  <a href={referrer}>
                    {/* <img className="hide-logo" src={logo} /> */}
                    <svg
                      width="94"
                      height="32"
                      viewBox="0 0 94 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M62.3844 7.78728H53.4123V32H41.7655V7.78728H32.7755V0.0179513H62.3844V7.78728ZM93.2247 7.66162V0H66.8219V24.3133C66.8219 28.5103 70.2255 31.9102 74.4189 31.9102H79.3627H93.3611V24.2127H79.8079C78.8278 24.2127 78.0307 23.4157 78.0307 22.4355V19.2761H92.6144V12.1853H78.0307V7.66162H93.2247V7.66162ZM19.7177 31.9749H0V0.0502637H19.6531C24.8482 0.0502637 29.056 4.26164 29.056 9.45316V22.6366C29.056 27.7922 24.8769 31.9749 19.7177 31.9749ZM18.2888 21.8826V10.0061C18.2888 8.65253 17.1902 7.5575 15.8402 7.5575H11.5283V24.3312H15.8402C17.1902 24.3312 18.2888 23.2362 18.2888 21.8826Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
                <div className="visible-xs col-xs-4 col-xs-offset-4 mobileData">
                  <svg
                    className="nav_home_img mobileIconDTE"
                    width="70"
                    height="45"
                    viewBox="0 0 90 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M62.3844 7.78728H53.4123V32H41.7655V7.78728H32.7755V0.0179513H62.3844V7.78728ZM93.2247 7.66162V0H66.8219V24.3133C66.8219 28.5103 70.2255 31.9102 74.4189 31.9102H79.3627H93.3611V24.2127H79.8079C78.8278 24.2127 78.0307 23.4157 78.0307 22.4355V19.2761H92.6144V12.1853H78.0307V7.66162H93.2247ZM19.7177 31.9749H0V0.0502637H19.6531C24.8482 0.0502637 29.056 4.26164 29.056 9.45316V22.6366C29.056 27.7922 24.8769 31.9749 19.7177 31.9749ZM18.2888 21.8826V10.0061C18.2888 8.65253 17.1902 7.5575 15.8402 7.5575H11.5283V24.3312H15.8402C17.1902 24.3312 18.2888 23.2362 18.2888 21.8826Z"
                      fill="white"
                    ></path>
                  </svg>
                  <p className="visible-xs mobileTextData">{title}</p>
                </div>
                {showCancelButton !== false && (
                  <div
                    id="header-cancel"
                    className="col-xs-4 col-md-4 mobileDataCancel pull-right"
                  >
                    {!location.pathname.includes('/reconnect') && (
                      <a
                        id="cancelTranscation"
                        className="payment-close-button pull-right payment-close-button-icon"
                        href={
                          programName
                            ? redirectUrlFallback
                            : mimo.type
                            ? mimoRedirectURl
                            : redirectUrl
                            ? redirectUrl
                            : referrer
                        }
                      >
                        Cancel
                      </a>
                    )}
                    {location.pathname.includes('/reconnect') && (
                      <a
                        id="cancelTranscation"
                        className="payment-close-button pull-right payment-close-button-icon"
                        href={reconnect.reconnectDetailsUrl}
                      >
                        Cancel
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  referrer: PropTypes.string.isRequired,
  title: PropTypes.string,
  showCancelButton: PropTypes.bool,
  redirectUrl: PropTypes.string.isRequired,
  redirectUrlFallback: PropTypes.string,
  mimo: PropTypes.object,
  oneTimePayment: PropTypes.object,
  reconnect: PropTypes.object
};

const mapStateToProps = state => {
  const {
    referrer,
    redirectUrl,
    redirectUrlFallback,
    mimo,
    oneTimePayment,
    reconnect
  } = state;
  return {
    referrer,
    redirectUrl,
    redirectUrlFallback,
    mimo,
    oneTimePayment,
    reconnect
  };
};

export default connect(mapStateToProps)(Header);
