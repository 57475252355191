import React, { Component, Fragment, lazy, Suspense } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { RecoilRoot } from 'recoil';

import configureStore from '../configureStore';
import { root } from '../sagas';

import { cookieState, clearReconnectCookies } from '../services/cookieState';
import { isPrepayEnabled } from '../services/utils';

import { AppLoader } from '../modules/common/components';

const AccountsError = lazy(() => import('./AccountsError.jsx'));
const PrepayPage = lazy(() => import('./PrepayPage.jsx'));
const AuthPay = lazy(() => import('./AuthPay.jsx'));
const AuthPayMimo = lazy(() => import('./AuthPay-Mimo.jsx'));
const AuthPayConfirm = lazy(() => import('./AuthPayConfirm.jsx'));
const CashOnlyError = lazy(() => import('./CashOnlyError.jsx'));
const EditPrepayPaymentMethodPage = lazy(() =>
  import('./EditPrepayPaymentMethodPage.jsx')
);
const GuestOneTimePayment = lazy(() => import('./GuestOneTimePayment.jsx'));
const GuestPay = lazy(() => import('./GuestPay.jsx'));
const GuestPayConfirm = lazy(() =>
  import('../modules/GuestPay/pages/GuestBillPay/GuestPayConfirm.jsx')
);
const NewPaymentMethod = lazy(() => import('./NewPaymentMethod.jsx'));
const NoActiveActError = lazy(() => import('./NoActiveAccountError.jsx'));
const PrepayCancel = lazy(() => import('./PrepayCancel.jsx'));
const PrepayConfirmationPage = lazy(() =>
  import('./PrepayConfirmationPage.jsx')
);
const ReviewAndSubmitPage = lazy(() => import('./ReviewAndSubmitPage.jsx'));
const ScheduledPaymentWarning = lazy(() =>
  import('./ScheduledPaymentWarning.jsx')
);
const ShutOffError = lazy(() => import('./ShutOffError.jsx'));
const NoticeSignIn = lazy(() => import('./NoticeSignIn.jsx'));
const AuthPayPaymentExtension = lazy(() =>
  import('./AuthPay-PaymentExtension.jsx')
);
const AuthPayConfirmPaymentExtension = lazy(() =>
  import('./AuthPayConfirm-PaymentExtension.jsx')
);
const AuthPayPaymentAgreement = lazy(() =>
  import('./AuthPay-PaymentAgreement.jsx')
);
const AuthPayConfirmPaymentAgreement = lazy(() =>
  import('./AuthPayConfirm-PaymentAgreement.jsx')
);
const AuthPayReconnect = lazy(() => import('./AuthPay-Reconnect.jsx'));
const AuthPayOneTimePayment = lazy(() => import('./AuthPay-OneTimePayment.jsx'));

const initialState = cookieState();
const store = configureStore(initialState);
store.runSaga(root);

const prepayEnabled = isPrepayEnabled();

if (location.pathname.includes('/reconnect')) {
  clearReconnectCookies();
}

export default class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <Suspense fallback={<AppLoader />}>
          <RecoilRoot>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/guest-pay" />
                </Route>
                <Route exact path="/pay-bill" component={AuthPay} />
                <Route
                  exact
                  path="/pay-bill/confirm"
                  component={AuthPayConfirm}
                />
                <Route exact path="/guest-pay" component={GuestPay} />
                <Route
                  exact
                  path="/guest-pay/confirm"
                  component={GuestPayConfirm}
                />
                <Route
                  exact
                  path="/add-payment-method"
                  component={NewPaymentMethod}
                />
                <Route
                  exact
                  path="/notice/accounts"
                  component={AccountsError}
                />
                <Route exact path="/notice/contact" component={ShutOffError} />
                <Route exact path="/notice/signin" component={NoticeSignIn} />
                <Route
                  exact
                  path="/notice/noActiveAct"
                  component={NoActiveActError}
                />
                <Route exact path="/notice/cash" component={CashOnlyError} />
                <Route exact path="/mimo" component={AuthPayMimo} />
                <Route
                  exact
                  path="/pay-bill/payment-agreement"
                  component={AuthPayPaymentAgreement}
                />
                <Route
                  exact
                  path="/pay-bill/confirm-payment-agreement"
                  component={AuthPayConfirmPaymentAgreement}
                />
                <Route
                  exact
                  path="/pay-bill/payment-extension"
                  component={AuthPayPaymentExtension}
                />
                <Route
                  exact
                  path="/pay-bill/confirm-payment-extension"
                  component={AuthPayConfirmPaymentExtension}
                />
                <Route
                  exact
                  path="/pay-bill/reconnect"
                  component={AuthPayReconnect}
                />
                <Route
                  exact
                  path="/reconnect/pay-bill/confirm"
                  component={AuthPayConfirm}
                />
                <Route
                  exact
                  path="/notice/scheduled"
                  component={ScheduledPaymentWarning}
                />
                <Route exact path="/one-time-payment" component={AuthPayOneTimePayment} />
                <Route
                  exact
                  path="/one-time-payment/confirm"
                  component={AuthPayConfirm}
                />
                <Route
                  exact
                  path="/guest-one-time-payment"
                  component={GuestOneTimePayment}
                />
                {prepayEnabled && (
                  <Fragment>
                    <Route exact path="/prepay" component={PrepayPage} />
                    <Route
                      exact
                      path="/review-submit"
                      component={ReviewAndSubmitPage}
                    />
                    <Route
                      exact
                      path="/prepay-cancel"
                      component={PrepayCancel}
                    />
                    <Route
                      exact
                      path="/prepay-confirmation"
                      component={PrepayConfirmationPage}
                    />
                    <Route
                      exact
                      path="/prepay-edit-payment"
                      component={EditPrepayPaymentMethodPage}
                    />
                  </Fragment>
                )}
                <Route>
                  <Redirect to="/guest-pay" />
                </Route>
              </Switch>
            </BrowserRouter>
          </RecoilRoot>
        </Suspense>
      </Provider>
    );
  }
}
