import React, { Fragment } from 'react';
import Checkbox from './Checkbox';
import Disclaimer from './Disclaimer';
import EditDelete from './EditDelete';
import showPaymentMethod from './showPaymentMethod';
import messages from './messages';

const BankAccountPayMethod = ({ cheques, first = false, isGuest, props }) => {
  const { checked, handleChange, paymentDetails, programName } = props;

  return cheques.map((cheque, index) => {
    const enableEdit = cheque.nickName ? true : false;
    const enableDelete =
      cheque.nickName && cheque.isUsedForPaymentProgram === 'false'
        ? true
        : false;
    return (
      <Fragment key={cheque.id}>
        <div
          className={`row pc-accordion-body-row ${
            first && index === 0 && isGuest === false
              ? 'padding-top-30px'
              : 'padding-top-10'
          } margin-right-0 margin-left-22`}
        >
          <div className="col-xs-1 col-sm-1 padding-l-r-15 text-center">
            <div className={`${isGuest ? 'padding-left-10px' : ''}`}>
              <Checkbox
                checked={checked}
                item={cheque}
                handleChange={handleChange}
                paymentDetails={paymentDetails}
              />
            </div>
          </div>
          <div className="col-xs-2 col-sm-1 padding-left0">
            <span className="pc-payment-account-number dte-bodyText-grey">
              <b>{messages.bankTextInUpperCase}</b>
            </span>
          </div>
          <div className="col-xs-9 col-sm-8">
            <span className="pc-payment-account-number dte-bodyText-grey">
              {showPaymentMethod({ item: cheque })}
            </span>
            {cheque.isUsedForPaymentProgram === 'true' && !programName && (
              <span className="padding-left-15px">
                {'  '}
                <em className="font-sm-2 dte-lawn">AutoPay</em>
              </span>
            )}
          </div>
          <span className="hidden-sm hidden-md hidden-lg">
            <Disclaimer
              paymentProgram={cheque.isUsedForPaymentProgram}
              programName={programName}
            />
          </span>
          <div className="hidden-sm hidden-md hidden-lg col-xs-6 padding-left0 col-xs-offset-6 col-sm-offset-0">
            <EditDelete
              paymentMethod={cheque}
              enableEdit={enableEdit}
              enableDelete={enableDelete}
              props={props}
            />
          </div>
          <span className="hidden-xs">
            <EditDelete
              paymentMethod={cheque}
              enableEdit={enableEdit}
              enableDelete={enableDelete}
              props={props}
            />
          </span>
          <span className="hidden-xs">
            <Disclaimer
              paymentProgram={cheque.isUsedForPaymentProgram}
              programName={programName}
            />
          </span>
        </div>
      </Fragment>
    );
  });
};

export default BankAccountPayMethod;
