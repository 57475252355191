import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import flatpickr from 'flatpickr';

import DateDisplay from './DateDisplay.jsx';

class PaymentDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      endDate: moment().add(60, 'days'),
      date: moment(),
      focused: true
    };
  }

  componentDidMount() {
    const {
      enableCalendar,
      enableScheduledDayDisabledColor,
      handleChange,
      maxDate,
      scheduledPayments
    } = this.props;

    if (enableCalendar) {
      flatpickr('#datepicker', {
        inline: true,
        defaultDate: moment().toISOString(),
        minDate: 'today',
        maxDate,
        onChange: (selectedDates, dateStr) => {
          const d = moment(dateStr);
          handleChange(d.toISOString());
          this.setState({ date: d });
        },
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          if (dayElem.classList.contains('today')) {
            dayElem.innerHTML = 'Today';
            dayElem.classList.add('background-dte-michigan');
          }

          if (
            enableScheduledDayDisabledColor &&
            scheduledPayments.includes(
              moment(dayElem.dateObj).format('YYYY-MM-DD')
            )
          ) {
            dayElem.classList.add('background-dte-cinderblock');
          }
        }
      });
    }
  }

  render() {
    const {
      collapsed,
      data,
      disableIcon,
      paymentDueText,
      paymentDueDate,
      scheduledPayments,
      toggle
    } = this.props;
    const collapsedClass = collapsed ? 'collapsed' : '';
    const hidden = collapsed ? 'hidden' : 'show';
    const singleAccount = Object.keys(data.accounts).length === 1;

    const expandContractIcon = [
      `${collapsed ? 'expand' : 'contract'}-icon`,
      `${disableIcon ? 'invisible' : ''}`
    ].join(' ');

    return (
      <div className="accordion-step panel border-borderGry">
        <div
          id="paymentDate-heading"
          className={`${collapsedClass} accordion-heading`}
          onClick={() => toggle('paymentDate')}
        >
          <div className="row">
            <div className="col-xs-6">
              <div className={`accordion-state-icon accordionIcon`}>
                <span className={expandContractIcon} />
              </div>
              <div className="accordion-label">
                <span className="total_amount_due">Payment Date</span>
              </div>
            </div>
            {<DateDisplay date={this.state.date} />}
          </div>
        </div>
        <div className={`row pc-accordion-body-row underline-row ${hidden}`}>
          <div className="col-xs-12 col-md-5">
            <div className="padding-md">
              {singleAccount && (
                <p className="padding-bottom-0 paymnet-date">
                  {paymentDueText}
                  <br />
                  <span className="dte-bodyText-grey">{paymentDueDate}</span>
                </p>
              )}
              {scheduledPayments && scheduledPayments.length > 0 && (
                <p className="paymnet-date">
                  Dates in grey indicate that a payment is already scheduled for
                  that day.
                </p>
              )}
            </div>
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="padding-md">
              <div id="datepicker" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentDate.propTypes = {
  collapsed: PropTypes.bool,
  data: PropTypes.object.isRequired,
  enableCalendar: PropTypes.bool,
  enableScheduledDayDisabledColor: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disableIcon: PropTypes.bool,
  maxDate: PropTypes.string,
  paymentDueDate: PropTypes.string,
  paymentDueText: PropTypes.string,
  scheduledPayments: PropTypes.array,
  toggle: PropTypes.func.isRequired
};

export default PaymentDate;
