import Cookies from 'js-cookie';

import * as location from './location';

function getPaymentAccounts() {
  let accounts = [];
  const details = Cookies.get('paymentAccounts');
  if (details) {
    accounts = details
      .trim()
      .split(',')
      .filter(account => account);
  }
  return accounts;
}

function getOrgBp() {
  let bp = '';
  const details = Cookies.get('orgBp');
  if (details) {
    bp = details.trim();
  }
  return bp;
}

function oneTimePayment() {
  return {
    amount: Cookies.get('amount') || '',
    email: Cookies.get('email') || '',
    firstName: Cookies.get('firstName') || '',
    lastName: Cookies.get('lastName') || '',
    programName: Cookies.get('programName') || '',
    role: Cookies.get('role') || ''
  };
}

function mimo() {
  return {
    type: Cookies.get('mimoType') || '',
    businessId: Cookies.get('businessId') || '',
    premisesId: Cookies.get('premisesId') || '',
    hash: Cookies.get('mimoHash') || ''
  };
}

function getAccounts() {
  let accounts = [];
  const details = Cookies.get('accounts');
  if (details) {
    accounts = details
      .trim()
      .split(',')
      .filter(account => account);
  }
  return accounts;
}

function paymentAgreement() {
  return {
    paymentAmount: Cookies.get('paymentAmount') || '',
    orgBp: Cookies.get('orgBp') || '',
    redirectUrl: Cookies.get('redirectUrl') || '', // redirected after confirmation
    businessId: Cookies.get('businessId') || '',
    premisesId: Cookies.get('premisesId') || '',
    installationNumber: Cookies.get('installationNumber') || '',
    paymentAccounts: Cookies.get('paymentAccounts') || '',
    paymentAgreementDetailsUrl: Cookies.get('reconnectDetailsUrl') || '' // used on cancel or error
  };
}

function paymentExtension() {
  return {
    pastDueAmount: Cookies.get('pastDueAmount') || '',
    peScheduledDate: Cookies.get('peScheduledDate') || '',
    peEligibleUrl: Cookies.get('peEligibleUrl') || '',
    peScheduledUrl: Cookies.get('peScheduledUrl') || '',
    accounts: getAccounts(),
    bpId: Cookies.get('bpId') || ''
  };
}

function reconnect() {
  return {
    paymentAmount: Cookies.get('paymentAmount') || '',
    paymentOption: Cookies.get('paymentOption:') || '',
    minimumAmountDue: Cookies.get('minimumAmountDue') || '',
    orgBp: Cookies.get('orgBp') || '',
    redirectUrl: Cookies.get('redirectUrl') || '',
    businessId: Cookies.get('businessId') || '',
    premisesId: Cookies.get('premisesId') || '',
    reconnectCashAllowed: Cookies.get('reconnectCashAllowed') || '',
    reconnectCardAllowed: Cookies.get('reconnectCardAllowed') || '',
    reconnectCheckAllowed: Cookies.get('reconnectCheckAllowed') || '',
    installationNumber: Cookies.get('installationNumber') || '',
    paymentAccounts: Cookies.get('paymentAccounts') || '',
    reconnectDetailsUrl: Cookies.get('reconnectDetailsUrl') || ''
  };
}

function getCustType() {
  let ct = '';
  const custType = Cookies.get('custType');
  if (custType) {
    ct = custType.trim();
  }
  return ct;
}

function redirectUrl() {
  let url = '';
  const details = Cookies.get('redirectUrl');
  if (details) {
    url = details.trim();
  }
  return url;
}

function redirectUrlFallback() {
  let url = '';
  const details = Cookies.get('redirectUrlFallback');
  if (details) {
    url = details.trim();
  }
  return url;
}

function getReferrer() {
  if (getCustType() === 'prepay') {
    return 'prepay';
  }

  return location.allowedOrFallbackRedirectHost(
    Cookies.get('originalReferrer')
  );
}

export function clearReconnectCookies() {
  Cookies.remove('paymentAmount');
  Cookies.remove('paymentOption:');
  Cookies.remove('minimumAmountDue');
  Cookies.remove('orgBp');
  Cookies.remove('redirectUrl');
  Cookies.remove('businessId');
  Cookies.remove('premisesId');
  Cookies.remove('reconnectCashAllowed');
  Cookies.remove('reconnectCardAllowed');
  Cookies.remove('reconnectCheckAllowed');
  Cookies.remove('installationNumber');
  Cookies.remove('paymentAccounts');
  Cookies.remove('reconnectDetailsUrl');
}

export function cookieState() {
  const cookieState = {
    accounts: getPaymentAccounts(),
    custType: getCustType(),
    orgBp: getOrgBp(),
    oneTimePayment: oneTimePayment(),
    mimo: mimo(),
    paymentExtension: paymentExtension(),
    reconnect: reconnect(),
    redirectUrl: redirectUrl(),
    redirectUrlFallback: redirectUrlFallback(),
    referrer: getReferrer(),
    paymentAgreement: paymentAgreement()
  };
  return cookieState;
}
