import React from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';

import { globalNameSpace } from '../constants';

const callbackName = `${globalNameSpace}_ReCaptcha_Callback`;
const uri = `https://www.google.com/recaptcha/api.js?onload=${callbackName}&render=explicit`;

class WrappedReCaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.widget = React.createRef();
    this.initCaptcha = this.initCaptcha.bind(this);
  }
  componentDidUpdate(prevProps) {
    const {
      grecaptcha,
      loading,
      failedNumber,
      failedAddress,
      failedOneTimePaymentVerification,
      searchBy,
      resetToken
    } = this.props;
    if (!prevProps.grecaptcha && grecaptcha) {
      this.initCaptcha();
    }
    if (
      (prevProps.loading &&
        !loading &&
        (failedNumber.state ||
          failedAddress.state ||
          failedOneTimePaymentVerification.state)) ||
      prevProps.searchBy !== searchBy
    ) {
      grecaptcha.reset();
      if (prevProps.searchBy !== searchBy) {
        resetToken();
      }
    }
  }
  initCaptcha() {
    const params = {
      sitekey: this.props.sitekey,
      callback: this.props.callback,
      'expired-callback': () => this.props.callback(null)
    };
    this.props.grecaptcha.render(this.widget.current, params);
  }
  render() {
    return <div ref={this.widget} />;
  }
}

WrappedReCaptcha.propTypes = {
  sitekey: PropTypes.string.isRequired,
  callback: PropTypes.func,
  grecaptcha: PropTypes.object,
  loading: PropTypes.bool,
  failedNumber: PropTypes.object,
  failedAddress: PropTypes.object,
  failedOneTimePaymentVerification: PropTypes.object,
  searchBy: PropTypes.string,
  resetToken: PropTypes.func
};
WrappedReCaptcha.defaultProps = {
  callback: () => {}
};

export default makeAsyncScriptLoader(uri, {
  callbackName,
  globalName: 'grecaptcha'
})(WrappedReCaptcha);
