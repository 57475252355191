import React, { Fragment } from 'react';
import { checkIfExpired } from '../../../../services/utils';

import Checkbox from './Checkbox';
import Disclaimer from './Disclaimer';
import EditDelete from './EditDelete';
import messages from './messages';
import getCcIcon from './getCcIcon';
import showPaymentMethod from './showPaymentMethod';

const CreditCardPayMethod = ({ cards, first = false, isGuest, props }) => {
  const {
    cardBlocking,
    checked,
    handleChange,
    paymentDetails,
    programName
  } = props;
  if (!cardBlocking) {
    return cards.map((card, index) => {
      const isExpired = checkIfExpired(card);
      const ccIcon = getCcIcon({ kind: card.kind });
      const enableEdit = card.nickName ? true : false;
      const enableDelete =
        card.nickName && card.isUsedForPaymentProgram === 'false'
          ? true
          : false;

      return (
        <Fragment key={card.id}>
          <div
            className={`row pc-accordion-body-row ${
              first && index === 0 && isGuest === false
                ? 'padding-top-30px'
                : 'padding-top-10'
            } margin-right-0 margin-left-22`}
          >
            <div className="col-xs-1 col-sm-1 padding-l-r-15 text-center">
              <div className={`${isGuest ? 'padding-left-10px' : ''}`}>
                <Checkbox
                  checked={checked}
                  handleChange={handleChange}
                  item={card}
                  paymentDetails={paymentDetails}
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-1 padding-left0">
              <div className="cc-icon-wrapper">
                <img className="card-logo-img-pm" src={ccIcon} />
              </div>
            </div>
            <div className="col-xs-9 col-sm-8">
              <span className="pc-payment-account-number dte-bodyText-grey">
                {showPaymentMethod({ item: card })}
              </span>
              <div className="col-xs-12 col-sm-4 expiration-method font-xs pc-expiration-method">
                {!isExpired ? (
                  <span>{`Expires ${card.expirationMonth}/${card.expirationYear}`}</span>
                ) : (
                  <span className="paymnet-date dte-tomato">{`Expired ${card.expirationMonth}/${card.expirationYear}`}</span>
                )}
                {card.isUsedForPaymentProgram === 'true' && !programName && (
                  <span className="padding-left-15px">
                    <em className="font-sm-2 dte-lawn">
                      {messages.autoPaytext}
                    </em>
                  </span>
                )}
              </div>
            </div>
            <span className="hidden-sm hidden-md hidden-lg">
              <Disclaimer
                paymentProgram={card.isUsedForPaymentProgram}
                programName={programName}
              />
            </span>
            <div className="hidden-sm hidden-md hidden-lg col-xs-6 padding-left0 col-xs-offset-6 col-sm-offset-0">
              <EditDelete
                enableEdit={enableEdit}
                enableDelete={enableDelete}
                paymentMethod={card}
                props={props}
              />
            </div>
            <span className="hidden-xs">
              <EditDelete
                enableEdit={enableEdit}
                enableDelete={enableDelete}
                paymentMethod={card}
                props={props}
              />
            </span>
            <span className="hidden-xs">
              <Disclaimer
                paymentProgram={card.isUsedForPaymentProgram}
                programName={programName}
              />
            </span>
          </div>
        </Fragment>
      );
    });
  }
  return [];
};

export default CreditCardPayMethod;
