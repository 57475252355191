import discoverSvg from '../../../../../static/Discover.svg';
import mastercardSvg from '../../../../../static/Mastercard.svg';
import visaSvg from '../../../../../static/Visa.svg';
import whiteDiscoverSvg from '../../../../../static/discover_v2.svg';
import whiteVisaSvg from '../../../../../static/visa_white.svg';


const getCcIcon = ({ isAccordionHeader, kind }) => {
  switch (kind) {
    case 'Discover':
      return isAccordionHeader === true ? whiteDiscoverSvg : discoverSvg;
    case 'MasterCard':
      return mastercardSvg;
    case 'Visa':
      return isAccordionHeader === true ? whiteVisaSvg : visaSvg;
    default:
      return '';
  }
};

export default getCcIcon;
