import React from 'react';
import PropTypes from 'prop-types';

import {
  formatAccountNumber,
  formatMoney,
  formatDate
} from '../../../../services/utils';
import messages from './messages';

const Addresses = props => {
  const { data } = props;
  return data.accounts.map(i => {
    const { accountNumber, dueDate, totalAmountDue, serviceAddress } = i;
    return (
      <div
        key={accountNumber}
        className="row accordion-body-row underline-row accordion-left-well accordion-left-well-align"
      >
        <div className="col-xs-12 col-sm-5 padding-top-sm">
          <span className="pull-left fontface-light customer-address pc-customer-address font-14px dte-bodyText-grey">
            <div>{serviceAddress.address}</div>
            <div>
              {`${serviceAddress.city}, ${serviceAddress.state} ${serviceAddress.zip}`}
            </div>
            <span className="account-number dte-bodyText-grey">
              {messages.accountNumberText} {formatAccountNumber(accountNumber)}
            </span>
          </span>
        </div>
        <div className="col-xs-9 col-sm-4 font-sm padding-top-sm due-date dte-bodyText-grey">
          {dueDate && (
            <span>
              {messages.dueByText}
              {formatDate(dueDate)}
            </span>
          )}
        </div>
        <div className="col-xs-3 col-sm-3 margin-bottom-20px">
          <div className="pull-right accordion-value currency pc-currency heading-color-black">
            {formatMoney(totalAmountDue)}
          </div>
        </div>
      </div>
    );
  });
};

Addresses.propTypes = {
  data: PropTypes.object
};

export default Addresses;
