import React from 'react';
import { checkIfExpired } from '../../../../services/utils';

const Checkbox = ({ checked, item, handleChange, paymentDetails }) => {
  const { id } = item;
  const changeItem = item.nickName ? item : paymentMethod;
  const isExpired = checkIfExpired(item);
  const paymentMethod = paymentDetails.find(i => i.id === id);

  if (!checked.hasOwnProperty(id)) {
    return null;
  }

  return (
    <>
      <input
        checked={checked[id]}
        className="css-checkbox payment-method-option pc-align-left"
        disabled={isExpired}
        id={id}
        name={id}
        onChange={() => {
          handleChange(changeItem);
        }}
        type="checkbox"
      />
      <label className="css-label pc-align-left" htmlFor={id} />
    </>
  );
};

export default Checkbox;
