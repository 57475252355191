import { all, fork, takeLatest, put, call } from 'redux-saga/effects';

import {
  GET_PAYMENT_PROGRAMS,
  GET_PAYMENT_CENTER_DATA,
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS,
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER,
  GET_GUEST_ONE_TIME_PAY_VERIFICATION,
  POST_GUEST_PAYMENT,
  GET_SAVED_PAYMENT_METHODS,
  UPDATE_SAVED_PAYMENT_METHOD,
  DELETE_SAVED_PAYMENT_METHOD,
  POST_SAVED_PAYMENT_METHOD,
  POST_AUTH_PAYMENT,
  POST_BULK_PAYMENT,
  POST_SPLIT_PAYMENT,
  GET_PAYMENT_HISTORY,
  GET_ACCESS_TOKEN,
  CHECK_CUSTOMER_DELINQUENCY,
  GET_GUEST_ACCESS_TOKEN,
  GET_TOKEN_RESPONSE,
  GET_PAYMENT_OPTIONS,
  RESET_RECAPTCHA_TOKEN
} from './actions';

import {
  filterDelinquencyAccountsWithData,
  filterValidDelinquencyAccounts
} from './services/utils';

import Dte from './api/dte';
import moment from 'moment';
const dte = new Dte();

export function* getPaymentPrograms(args) {
  try {
    const mappedCalls = args.accounts.reduce((collection, account) => {
      collection[account] = call(dte.getPaymentPrograms, account);
      return collection;
    }, {});
    const responses = yield all(mappedCalls);
    const data = Object.keys(responses).reduce((collection, account) => {
      collection[account] = responses[account].data;
      return collection;
    }, {});
    yield put({ type: GET_PAYMENT_PROGRAMS.SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_PAYMENT_PROGRAMS.FAILURE, err });
  }
}

export function* getPaymentCenterData(args) {
  let { premisesId, businessId, accounts, orgBp } = args.data;

  let delinquencyAccounts = {};
  // get mimo accounts if appplicable
  if (premisesId) {
    try {
      const { data } = yield call(dte.checkCustomerDelinquency, {
        premisesId,
        businessId
      });
      yield put({
        type: CHECK_CUSTOMER_DELINQUENCY.SUCCESS,
        data
      });

      // remove transient accounts as they will not have any data in
      // paymentCenterData call
      const filteredAccounts = filterDelinquencyAccountsWithData(
        data.delinquencyDetail
      ).map(account => `${account.contractAccount}`);

      delinquencyAccounts = filterValidDelinquencyAccounts(
        data.delinquencyDetail
      );

      accounts = [...filteredAccounts];
    } catch (err) {
      yield put({ type: CHECK_CUSTOMER_DELINQUENCY.FAILURE, err });
    }
  }

  try {
    const { paymentCenterData, paymentOptions } = yield all({
      paymentCenterData: call(dte.getPaymentCenterData, accounts),
      paymentOptions: call(dte.getPaymentOptions, orgBp)
    });

    const { cardBlocking } = paymentOptions.data;
    yield put({ type: GET_PAYMENT_OPTIONS.SUCCESS, cardBlocking });
    console.log('looking for payment data in saga', paymentCenterData.data);
    if (paymentCenterData.data.accounts) {
      if (
        paymentCenterData.data.totalAmountDue &&
        parseFloat(paymentCenterData.data.totalAmountDue) < 0
      ) {
        paymentCenterData.data.totalAmountDue = '0.0';
      }
      paymentCenterData.data.accounts.forEach(payAccount => {
        if (parseFloat(payAccount.totalAmountDue) < 0) {
          payAccount.totalAmountDue = Math.round(0).toString();
        }
      });
    }
    console.log('looking for payment data in saga', paymentCenterData.data);

    // This signifies that a mimo account is present, this will add any
    // transient accounts to the accounts array ensuring that accounts are all
    // found in one place
    if (premisesId) {
      // If accounts are empty the getPaymentCenterData returns all accounts
      // assigned to the orgBp. This ensures that only the customerDelinquency
      // accounts are present.
      const mockAccounts = [];
      for (const num in delinquencyAccounts) {
        const account = delinquencyAccounts[num];
        let serviceAddress = { address: '', city: '', state: '', zip: '' };
        if (account.premise) {
          const {
            houseNumber,
            preDirection,
            streetAddress,
            secondaryCode,
            secondaryNumber,
            city,
            state,
            zipCode
          } = account.premise;

          serviceAddress = {
            address: `${houseNumber || ''} ${preDirection ||
              ''} ${streetAddress || ''} ${secondaryCode ||
              ''} ${secondaryNumber || ''}`.trim(),
            city: city || '',
            state: state || '',
            zip: zipCode || ''
          };
        }

        const mockAccount = {
          accountNumber: num,
          allowedPaymentTypes: ['card', 'cheque', 'cash'],
          dueDate: moment().format('YYYY-MM-DD'),
          minimumDue: account.amount || '0.0',
          serviceAddress,
          statusBarMessages: [],
          totalAmountDue: account.amount || '0.0'
        };
        mockAccounts.push(mockAccount);
      }

      paymentCenterData.data.accounts = mockAccounts;

      // Once we merge accounts this way we need redo the total amount due.
      let totalDue = 0.0;
      for (let account of paymentCenterData.data.accounts) {
        if (parseFloat(account.totalAmountDue) < 0) {
          account.totalAmountDue = 0.0;
        }
        totalDue += parseFloat(account.totalAmountDue);
      }
      paymentCenterData.data.totalAmountDue = (
        Math.round(totalDue * 100) / 100
      ).toString();
    }

    yield put({
      type: GET_PAYMENT_CENTER_DATA.SUCCESS,
      data: paymentCenterData.data
    });
  } catch (err) {
    yield put({ type: GET_PAYMENT_CENTER_DATA.FAILURE, err });
  }
}

export function* getGuestPayAccountDataByAccountNumber(args) {
  try {
    const { data } = yield call(
      dte.getGuestPayAccountDataByAccountNumber,
      args.accounts
    );
    const paymentOptions = yield call(
      dte.getGuestPaymentOptions,
      data.paymentToken
    );
    const { cardBlocking } = (yield paymentOptions).data;
    yield put({ type: GET_PAYMENT_OPTIONS.SUCCESS, cardBlocking });

    yield put({
      type: GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.FAILURE,
      err
    });
  }
}

export function* getGuestOneTimePayVerification(args) {
  try {
    const { data } = yield call(
      dte.getGuestOneTimePayVerification,
      args.params
    );

    yield put({ type: GET_PAYMENT_OPTIONS.SUCCESS, cardBlocking: false });

    yield put({
      type: GET_GUEST_ONE_TIME_PAY_VERIFICATION.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: GET_GUEST_ONE_TIME_PAY_VERIFICATION.FAILURE,
      err
    });

    yield put({ type: RESET_RECAPTCHA_TOKEN });
  }
}

export function* getGuestPayAccountDataByAddress(args) {
  try {
    const { data } = yield call(
      dte.getGuestPayAccountDataByAddress,
      args.accounts
    );
    const paymentOptions = yield call(
      dte.getGuestPaymentOptions,
      data.paymentToken
    );
    const { cardBlocking } = (yield paymentOptions).data;
    yield put({ type: GET_PAYMENT_OPTIONS.SUCCESS, cardBlocking });

    yield put({
      type: GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.FAILURE,
      err
    });
  }
}

export function* postGuestPayment(args) {
  try {
    const { data } = yield call(dte.postGuestPayment, args.paymentDetails);
    data.paymentMethod = args.paymentDetails.paymentMethod;
    yield put({
      type: POST_GUEST_PAYMENT.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: POST_GUEST_PAYMENT.FAILURE,
      err
    });
  }
}

export function* getSavedPaymentMethods(args) {
  try {
    const { data } = yield call(dte.getSavedPaymentMethods, args.orgBp);
    yield put({ type: GET_SAVED_PAYMENT_METHODS.SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_SAVED_PAYMENT_METHODS.FAILURE, err });
  }
}

export function* updateSavedPaymentMethod(args) {
  try {
    const { data } = yield call(dte.putSavedPaymentMethod, args.item);
    yield put({ type: UPDATE_SAVED_PAYMENT_METHOD.SUCCESS, data });
  } catch (err) {
    yield put({ type: UPDATE_SAVED_PAYMENT_METHOD.FAILURE, err });
  }
}

export function* deleteSavedPaymentMethod(args) {
  try {
    const { data } = yield call(dte.deleteSavedPaymentMethod, args.params);
    yield put({ type: DELETE_SAVED_PAYMENT_METHOD.SUCCESS, data });
  } catch (err) {
    yield put({ type: DELETE_SAVED_PAYMENT_METHOD.FAILURE, err });
  }
}

export function* postSavedPaymentMethod(args) {
  try {
    const { data } = yield call(dte.postSavedPaymentMethod, args.paymentInfo);
    yield put({ type: POST_SAVED_PAYMENT_METHOD.SUCCESS, data });
  } catch (err) {
    yield put({ type: POST_SAVED_PAYMENT_METHOD.FAILURE, err });
  }
}

export function* postAuthPayment(args) {
  try {
    const { data } = yield call(dte.postAuthPayment, args.paymentDetails);
    data.paymentMethod = args.paymentMethod;
    yield put({
      type: POST_AUTH_PAYMENT.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: POST_AUTH_PAYMENT.FAILURE,
      err,
      data: { paymentMethod: args.paymentMethod }
    });
  }
}

export function* postBulkPayment(args) {
  try {
    const { data } = yield call(dte.postBulkPayment, args.paymentDetails);
    data.paymentMethod = args.paymentMethod;
    yield put({
      type: POST_BULK_PAYMENT.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: POST_BULK_PAYMENT.FAILURE,
      err,
      data: { paymentMethod: args.paymentMethod }
    });
  }
}

export function* postSplitPayment(args) {
  const { paymentMethods, paymentDetails } = args;
  try {
    const { data } = yield call(dte.postSplitPayment, paymentDetails);
    data.confirmation.map((confirmation, index) => {
      const {
        paymentDetail: { paymentMethodId, lastFour }
      } = confirmation;
      const paymentMethod = paymentMethods.find(item => {
        if (item.paymentMethodId) {
          return item.paymentMethodId === paymentMethodId;
        } else {
          const { bankAccountNumber, cardToken } = item.values;
          if (bankAccountNumber) {
            return parseInt(bankAccountNumber.slice(-4)) === parseInt(lastFour);
          } else {
            return cardToken === paymentMethodId;
          }
        }
      });
      data.confirmation[index].paymentMethod = paymentMethod;
    });

    yield put({
      type: POST_SPLIT_PAYMENT.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: POST_SPLIT_PAYMENT.FAILURE,
      err
    });
  }
}

export function* getPaymentHistory(args) {
  try {
    const mappedCalls = args.accounts.reduce((collection, account) => {
      collection[account] = call(dte.getPaymentHistory, account);
      return collection;
    }, {});
    const responses = yield all(mappedCalls);
    const data = Object.keys(responses).reduce((collection, account) => {
      collection[account] = responses[account].data.accounts;
      return collection;
    }, {});
    yield put({ type: GET_PAYMENT_HISTORY.SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_PAYMENT_HISTORY.FAILURE, err });
  }
}

export function* getAccessToken() {
  try {
    const { data } = yield call(dte.getAccessToken);
    yield put({ type: GET_ACCESS_TOKEN.SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_ACCESS_TOKEN.FAILURE, err });
  }
}

export function* getGuestAccessToken(args) {
  try {
    const { data } = yield call(dte.getGuestAccessToken, args.paymentToken);
    yield put({
      type: GET_GUEST_ACCESS_TOKEN.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: GET_GUEST_ACCESS_TOKEN.FAILURE,
      err
    });
  }
}

export function* getTokenResponse(args) {
  try {
    let data;
    if (args.paymentToken) {
      const response = yield call(
        dte.getGuestTokenResponse,
        args.accessToken,
        args.merchantGuid,
        args.paymentToken
      );
      data = response.data;
    } else {
      const response = yield call(
        dte.getTokenResponse,
        args.accessToken,
        args.merchantGuid
      );
      data = response.data;
    }
    yield put({
      type: GET_TOKEN_RESPONSE.SUCCESS,
      data
    });
  } catch (err) {
    yield put({ type: GET_TOKEN_RESPONSE.FAILURE, err });
  }
}

export function* getPaymentOptions(args) {
  try {
    const {
      data: { cardBlocking }
    } = yield call(dte.getPaymentOptions, args.orgBp);
    yield put({
      type: GET_PAYMENT_OPTIONS.SUCCESS,
      cardBlocking
    });
  } catch (err) {
    yield put({ type: GET_PAYMENT_OPTIONS.FAILURE, err });
  }
}

export function* watchGetPaymentPrograms() {
  yield takeLatest(GET_PAYMENT_PROGRAMS.REQUEST, getPaymentPrograms);
}

export function* watchGetPaymentCenterData() {
  yield takeLatest(GET_PAYMENT_CENTER_DATA.REQUEST, getPaymentCenterData);
}

export function* watchGetGuestPayAccountDataByAddress() {
  yield takeLatest(
    GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.REQUEST,
    getGuestPayAccountDataByAddress
  );
}

export function* watchGetGuestPayAccountDataByAccountNumber() {
  yield takeLatest(
    GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.REQUEST,
    getGuestPayAccountDataByAccountNumber
  );
}

export function* watchGetGuestOneTimePayVerification() {
  yield takeLatest(
    GET_GUEST_ONE_TIME_PAY_VERIFICATION.REQUEST,
    getGuestOneTimePayVerification
  );
}

export function* watchPostGuestPayment() {
  yield takeLatest(POST_GUEST_PAYMENT.REQUEST, postGuestPayment);
}

export function* watchGetSavedPaymentMethods() {
  yield takeLatest(GET_SAVED_PAYMENT_METHODS.REQUEST, getSavedPaymentMethods);
}

export function* watchUpdateSavedPaymentMethod() {
  yield takeLatest(
    UPDATE_SAVED_PAYMENT_METHOD.REQUEST,
    updateSavedPaymentMethod
  );
}

export function* watchDeleteSavedPaymentMethod() {
  yield takeLatest(
    DELETE_SAVED_PAYMENT_METHOD.REQUEST,
    deleteSavedPaymentMethod
  );
}

export function* watchPostSavedPaymentMethod() {
  yield takeLatest(POST_SAVED_PAYMENT_METHOD.REQUEST, postSavedPaymentMethod);
}

export function* watchPostAuthPayment() {
  yield takeLatest(POST_AUTH_PAYMENT.REQUEST, postAuthPayment);
}

export function* watchPostBulkPayment() {
  yield takeLatest(POST_BULK_PAYMENT.REQUEST, postBulkPayment);
}

export function* watchPostSplitPayment() {
  yield takeLatest(POST_SPLIT_PAYMENT.REQUEST, postSplitPayment);
}

export function* watchGetPaymentHistory() {
  yield takeLatest(GET_PAYMENT_HISTORY.REQUEST, getPaymentHistory);
}

export function* watchGetAccessToken() {
  yield takeLatest(GET_ACCESS_TOKEN.REQUEST, getAccessToken);
}

export function* watchGetGuestAccessToken() {
  yield takeLatest(GET_GUEST_ACCESS_TOKEN.REQUEST, getGuestAccessToken);
}

export function* watchGetTokenResponse() {
  yield takeLatest(GET_TOKEN_RESPONSE.REQUEST, getTokenResponse);
}

export function* watchGetPaymentOptions() {
  yield takeLatest(GET_PAYMENT_OPTIONS.REQUEST, getPaymentOptions);
}

export function* root() {
  yield all([
    fork(watchGetPaymentCenterData),
    fork(watchGetGuestPayAccountDataByAccountNumber),
    fork(watchGetGuestPayAccountDataByAddress),
    fork(watchPostGuestPayment),
    fork(watchGetSavedPaymentMethods),
    fork(watchUpdateSavedPaymentMethod),
    fork(watchDeleteSavedPaymentMethod),
    fork(watchPostSavedPaymentMethod),
    fork(watchPostAuthPayment),
    fork(watchPostBulkPayment),
    fork(watchPostSplitPayment),
    fork(watchGetPaymentPrograms),
    fork(watchGetPaymentHistory),
    fork(watchGetAccessToken),
    fork(watchGetGuestAccessToken),
    fork(watchGetTokenResponse),
    // fork(watchCheckCustomerDelinquency),
    fork(watchGetPaymentOptions),
    fork(watchGetGuestOneTimePayVerification)
  ]);
}
