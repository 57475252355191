import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import PaymentAmountInput from './PaymentAmountInput';

const InputAmountHeader = props => {
  const { accountNumber, collapsed, singleAccount, values } = props;

  return (
    <Fragment>
      <div className="col-xs-6 col-sm-5 col-md-4">
        <Icon collapsed={collapsed} singleAccount={singleAccount} />
      </div>
      <div className="col-xs-6 col-sm-7 col-md-8">
        <PaymentAmountInput
          accountNumber={accountNumber}
          value={values[accountNumber]}
          {...props}
        />
      </div>
    </Fragment>
  );
};

InputAmountHeader.propTypes = {
  accountNumber: PropTypes.string,
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  getPaymentAmountInput: PropTypes.func,
  showInputAmountHeader: PropTypes.bool,
  singleAccount: PropTypes.bool,
  values: PropTypes.object
};

export default InputAmountHeader;
