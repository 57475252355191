import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import messages from './messages';
import { formatFloat, formatFloatToCurrency } from '../../../../services/utils';

const GuestPaymentAmount = props => {
  const { balance, searchBy } = props;

  const [paymentAmount, setPaymentAmount] = useState('0.00');

  useEffect(() => {
    if (searchBy === 'account-num') {
      if (parseFloat(balance) >= 1) {
        setPaymentAmount(parseFloat(balance).toFixed(2));
      }
    }
  }, []);

  useEffect(() => {
    props.callbackToGuestPay(paymentAmount);
  }, [paymentAmount]);

  return (
    <section className="payment-amount-step panel accordion-step borderNone">
      <div className="padding-20px">
        <div className="row">
          <div className="col-xs-12 col-sm-9">
            <div className="accordion-label">
              <span className="dte-n-rider">{messages.paymentAmountText}</span>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 vcenter">
            <div className="payment-input text-right sm-padding-top-md-plus">
              <div className="input-group">
                <span className="input-group-addon input-select-payment">
                 {messages.dollarSign}
                </span>
                <input
                  className="blackDrkImp form-control small-input text-input-height text-right accordion-amt-value currency pc-currency"
                  name="payment_amount"
                  onChange={e => setPaymentAmount(formatFloat(e.target.value))}
                  onBlur={e => {
                    const val = e.target.value || '0';
                    setPaymentAmount(formatFloatToCurrency(val, true));
                  }}
                  onFocus={() => setPaymentAmount('')}
                  type="text"
                  value={paymentAmount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

GuestPaymentAmount.propTypes = {
  balance: PropTypes.number,
  callbackToGuestPay: PropTypes.func.isRequired,
  searchBy: PropTypes.string.isRequired
};

export default GuestPaymentAmount;
