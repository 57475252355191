import React from 'react';
import PropTypes from 'prop-types';

import SingleAccountAmountDue from './SingleAccountAmountDue';
import MultipleAccountAmountDue from './MultipleAccountAmountDue';

const AmountDue = props => {
  const {
    data,
    toggle,
    collapsed,
    isSingleAccount,
    customerDelinquency
  } = props;

  const singleAccount = isSingleAccount || data.accounts.length === 1;

  return (
    <div className="accordion-step panel border-gray-top-0 border-bottom-none">
      {singleAccount ? (
        <SingleAccountAmountDue
          customerDelinquency={customerDelinquency}
          data={data}
          isSingleAccount={isSingleAccount}
        />
      ) : (
        <MultipleAccountAmountDue
          data={data}
          collapsed={collapsed}
          singleAccount={singleAccount}
          toggle={toggle}
        />
      )}
    </div>
  );
};

AmountDue.propTypes = {
  data: PropTypes.object,
  toggle: PropTypes.func,
  collapsed: PropTypes.bool,
  isSingleAccount: PropTypes.bool,
  customerDelinquency: PropTypes.object
};

export default AmountDue;
