import { combineReducers } from 'redux';
import {
  GET_PAYMENT_PROGRAMS,
  GET_PAYMENT_CENTER_DATA,
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER,
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS,
  GET_GUEST_ONE_TIME_PAY_VERIFICATION,
  POST_GUEST_PAYMENT,
  RESET_SEARCH_FORM,
  GET_SAVED_PAYMENT_METHODS,
  UPDATE_SAVED_PAYMENT_METHOD,
  DELETE_SAVED_PAYMENT_METHOD,
  POST_SAVED_PAYMENT_METHOD,
  POST_AUTH_PAYMENT,
  POST_BULK_PAYMENT,
  POST_SPLIT_PAYMENT,
  GET_PAYMENT_HISTORY,
  GET_ACCESS_TOKEN,
  RESET_RECAPTCHA,
  RESET_FAILED,
  CLEAR_ACCESS_TOKEN,
  CLEAR_TOKEN_RESPONSE,
  CHECK_CUSTOMER_DELINQUENCY,
  GET_GUEST_ACCESS_TOKEN,
  GET_PAYMENT_OPTIONS,
  GET_TOKEN_RESPONSE,
  SET_RECAPTCHA_TOKEN,
  RESET_RECAPTCHA_TOKEN
} from './actions';

import { actionTypeDestructure } from './services/utils';

export function accounts(state = [], action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function custType(state = '') {
  return state;
}

export function orgBp(state = '') {
  return state;
}

export function referrer(state = '') {
  return state;
}

export function redirectUrl(state = '') {
  return state;
}

export function redirectUrlFallback(state = '') {
  return state;
}

export function paymentPrograms(state = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_PROGRAMS.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function eBill(state = false, action) {
  switch (action.type) {
    case GET_PAYMENT_PROGRAMS.SUCCESS:
      const enrollments = action.data;
      for (let account in enrollments) {
        for (let enrollment of enrollments[account].eligiblePrograms) {
          if (enrollment.name === 'EBL') {
            return true;
          }
        }
      }
      return false;
    default:
      return state;
  }
}

export function paymentCenterData(state = {}, action) {
  switch (action.type) {
    case GET_PAYMENT_CENTER_DATA.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function guestPayAccountData(state = {}, action) {
  switch (action.type) {
    case GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.SUCCESS:
    case GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.SUCCESS:
    case GET_GUEST_ONE_TIME_PAY_VERIFICATION.SUCCESS:
      return action.data;
    case RESET_SEARCH_FORM:
      return {};
    default:
      return state;
  }
}

export function guestPayment(state = {}, action) {
  switch (action.type) {
    case POST_GUEST_PAYMENT.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function savedPaymentMethods(state = {}, action) {
  switch (action.type) {
    case GET_SAVED_PAYMENT_METHODS.SUCCESS:
    case UPDATE_SAVED_PAYMENT_METHOD.SUCCESS:
    case DELETE_SAVED_PAYMENT_METHOD.SUCCESS:
    case POST_SAVED_PAYMENT_METHOD.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function paymentMethodActions(state = [], action) {
  switch (action.type) {
    case UPDATE_SAVED_PAYMENT_METHOD.SUCCESS:
      return [
        {
          text: 'Your payment method has been updated.',
          type: 'AS_PaymentMethodUpdatedStsMsg',
          createdAt: new Date().toISOString()
        }
      ];
    case DELETE_SAVED_PAYMENT_METHOD.SUCCESS:
      return [
        {
          text: 'Your payment method has been deleted.',
          type: 'AS_PaymentMethodUpdatedStsMsg',
          createdAt: new Date().toISOString()
        }
      ];
    case POST_SAVED_PAYMENT_METHOD.SUCCESS:
      return [
        {
          text: 'Your payment method has been added.',
          type: 'AS_PaymentMethodUpdatedStsMsg',
          createdAt: new Date().toISOString()
        }
      ];
    default:
      return state;
  }
}

export function authPayment(state = {}, action) {
  switch (action.type) {
    case POST_AUTH_PAYMENT.SUCCESS:
    case POST_BULK_PAYMENT.SUCCESS:
    case POST_SPLIT_PAYMENT.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function scheduledPayments(state = null, action) {
  switch (action.type) {
    case GET_PAYMENT_HISTORY.SUCCESS:
      const result = [];
      for (const accountNumber in action.data) {
        for (const account of action.data[accountNumber]) {
          for (const item of account.payments) {
            if (item.status === 'Scheduled') {
              result.push(item.scheduledDate);
            }
          }
        }
      }
      return result;
    default:
      return state;
  }
}

export function paymetricTokens(
  state = { accessToken: '', merchantGuid: '' },
  action
) {
  switch (action.type) {
    case GET_ACCESS_TOKEN.SUCCESS:
    case GET_GUEST_ACCESS_TOKEN.SUCCESS:
      return Object.assign(
        {},
        {
          accessToken: action.data.accessToken,
          merchantGuid: action.data.merchantGuid
        }
      );
    case GET_ACCESS_TOKEN.FAILURE:
    case GET_GUEST_ACCESS_TOKEN.FAILURE:
    case GET_TOKEN_RESPONSE.SUCCESS:
    case GET_TOKEN_RESPONSE.FAILURE:
    case CLEAR_ACCESS_TOKEN:
      return { accessToken: '', merchantGuid: '' };
    default:
      return state;
  }
}

export function paymetricTokenResponse(state = { details: null }, action) {
  switch (action.type) {
    case GET_TOKEN_RESPONSE.SUCCESS:
      return { details: action.data };
    case CLEAR_TOKEN_RESPONSE:
    case GET_TOKEN_RESPONSE.FAILURE:
      return { details: null };
    default:
      return state;
  }
}

export function customerDelinquency(state = {}, action) {
  switch (action.type) {
    case CHECK_CUSTOMER_DELINQUENCY.SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export function oneTimePayment(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function mimo(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function paymentAgreement(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function paymentExtension(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function reconnect(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function cardBlocking(state = true, action) {
  switch (action.type) {
    case GET_PAYMENT_OPTIONS.SUCCESS:
      return action.cardBlocking;
    default:
      return state;
  }
}

export function loading(state = {}, action) {
  const atd = actionTypeDestructure(action.type);
  if (!atd) {
    return state;
  }
  const { requestName, requestState } = atd;
  return Object.assign({}, state, {
    [requestName]: requestState === 'REQUEST'
  });
}

export function recaptchaToken(state = '', action) {
  switch (action.type) {
    case RESET_RECAPTCHA_TOKEN:
      return '';
    case SET_RECAPTCHA_TOKEN:
      return action.token;
    default:
      return state;
  }
}

export function failed(state = {}, action) {
  const defaultState = {
    state: false,
    code: 200,
    message: ''
  };

  if (action.type === RESET_SEARCH_FORM) {
    return Object.keys(state).reduce((result, key) => {
      result[key] = Object.assign({}, defaultState);
      return result;
    }, {});
  }

  if (action.type === RESET_RECAPTCHA) {
    return Object.assign({}, state, {
      [GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.name]: Object.assign(
        {},
        defaultState
      ),
      [GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.name]: Object.assign(
        {},
        defaultState
      ),
      [GET_GUEST_ONE_TIME_PAY_VERIFICATION.name]: Object.assign(
        {},
        defaultState
      )
    });
  }

  if (action.type === RESET_FAILED) {
    return Object.assign({}, state, {
      [action.key]: Object.assign({}, defaultState)
    });
  }

  const atd = actionTypeDestructure(action.type);
  if (!atd) {
    return state;
  }

  const { requestName, requestState } = atd;

  let newRequestState = {
    state: false,
    code: undefined,
    message: undefined
  };

  if (requestState === 'FAILURE') {
    newRequestState = {
      state: true,
      code:
        action.err && action.err.response ? action.err.response.status : 500,
      message:
        action.err && action.err.response
          ? action.err.response.data.errorMessage
          : ''
    };
  }

  const nextState = {
    [requestName]: newRequestState
  };

  // reset error message for save after deleting a method
  if (requestName === DELETE_SAVED_PAYMENT_METHOD.name) {
    nextState[POST_SAVED_PAYMENT_METHOD.name] = Object.assign({}, defaultState);
  }
  return Object.assign({}, state, nextState);
}

const rootReducer = combineReducers({
  paymentCenterData,
  guestPayAccountData,
  guestPayment,
  savedPaymentMethods,
  authPayment,
  loading,
  failed,
  accounts,
  referrer,
  redirectUrl,
  redirectUrlFallback,
  paymentPrograms,
  custType,
  orgBp,
  eBill,
  scheduledPayments,
  paymetricTokens,
  oneTimePayment,
  mimo,
  paymentAgreement,
  paymentExtension,
  reconnect,
  customerDelinquency,
  cardBlocking,
  paymetricTokenResponse,
  paymentMethodActions,
  recaptchaToken
});

export default rootReducer;
