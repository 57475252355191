import urlParse from 'url-parse';
import * as qs from 'query-string';
import Cookies from 'js-cookie';

import {
  homePageRedirectURI,
  mimoPageRedirectURI,
  mimoHomePageURI
} from '../constants';

const ALLOWED_DOMAINS = ['dteco.com', 'dteenergy.com'];
const FALLBACK_HOST = API_HOST;

export function getReferrer() {
  return Cookies.get('originalReferrer') || document.referrer;
}

export function allowedOrigin(origin) {
  for (let domain of ALLOWED_DOMAINS) {
    if (origin.endsWith(domain)) {
      return true;
    }
  }
  return false;
}

export function getReferrerOrigin() {
  const referrer = getReferrer();
  if (referrer) {
    return urlParse(referrer).origin;
  }
}

export function getHostOrigin() {
  return urlParse(window.location.href).origin;
}

export function hostAndReferrerMatch() {
  return getHostOrigin() === getReferrerOrigin();
}

export function allowedOrFallbackApiHost() {
  if (hostAndReferrerMatch() === true) {
    return FALLBACK_HOST;
  }
  const origin = getReferrerOrigin();
  if (origin && origin.includes('advantage')) {
    return FALLBACK_HOST;
  }

  if (origin && origin.includes('prepay')) {
    return FALLBACK_HOST;
  }

  if (origin && origin.includes('solution')) {
    return FALLBACK_HOST;
  }

  if (origin && origin.includes('startstop')) {
    return FALLBACK_HOST;
  }

  return FALLBACK_HOST;
}

export function allowedOrFallbackRedirectHost(cookieReferrer = '') {
  const origin = getReferrerOrigin(cookieReferrer);
  if (origin && allowedOrigin(origin) === true) {
    return getReferrer();
  }
  return homePageRedirectURI;
}

export function forceRedirect(url) {
  window.location.assign(url);
}

export function getMIMOHomepageRedirect() {
  const origin = getReferrerOrigin();

  const MIMOHomepageRedirect = origin.includes('startstop')
    ? origin
    : `${origin}${mimoHomePageURI}`;

  return MIMOHomepageRedirect;
}

export function getMIMORedirect(hash, account, type) {
  // let redirect = allowedOrFallbackRedirectHost();
  // if (redirect === homePageRedirectURI) {
  //   redirect = mimoPageRedirectURI;
  // }
  let redirect;
  const origin = getReferrerOrigin();
  const referrer = getReferrer();
  if (origin && allowedOrigin(origin) === true) {
    redirect = referrer;
    redirect =
      redirect.indexOf('commercial') > -1
        ? redirect
        : `${origin}/commercial/mimo-${type}/`;

    const redirectUrl = Cookies.get('redirectUrl');
    if (redirectUrl && redirectUrl.includes('startstop')) {
      redirect = redirectUrl;
    }
  } else {
    redirect = mimoPageRedirectURI;
  }
  const parse = urlParse(redirect);
  const additionalQuery = {
    successful: true
  };
  if (account) {
    additionalQuery.contractAccount = account;
  }
  parse.set('query', Object.assign({}, qs.parse(parse.query), additionalQuery));
  if (hash) {
    parse.set('hash', hash);
  }
  return parse.href;
}

export function appendSuccessToRedirect(url) {
  const parse = urlParse(url);
  parse.set(
    'query',
    Object.assign({}, qs.parse(parse.query), { successful: true })
  );
  return parse.href;
}
