import * as axios from 'axios';
import { loginPageRedirectURI } from '../constants';
import * as location from '../services/location';
import * as paymetric from '../services/paymetric';

axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response.status === 401) {
      location.forceRedirect(loginPageRedirectURI);
    }
    return Promise.reject(err);
  }
);

export default class Dte {
  constructor() {
    this.getPaymentCenterData = this.getPaymentCenterData.bind(this);
    this.getGuestPayAccountDataByAccountNumber = this.getGuestPayAccountDataByAccountNumber.bind(
      this
    );
    this.getGuestPayAccountDataByAddress = this.getGuestPayAccountDataByAddress.bind(
      this
    );
    this.postGuestPayment = this.postGuestPayment.bind(this);
    this.getSavedPaymentMethods = this.getSavedPaymentMethods.bind(this);
    this.putSavedPaymentMethod = this.putSavedPaymentMethod.bind(this);
    this.deleteSavedPaymentMethod = this.deleteSavedPaymentMethod.bind(this);
    this.postSavedPaymentMethod = this.postSavedPaymentMethod.bind(this);
    this.postAuthPayment = this.postAuthPayment.bind(this);
    this.postBulkPayment = this.postBulkPayment.bind(this);
    this.postSplitPayment = this.postSplitPayment.bind(this);
    this.getPaymentPrograms = this.getPaymentPrograms.bind(this);
    this.getPaymentHistory = this.getPaymentHistory.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getGuestAccessToken = this.getGuestAccessToken.bind(this);
    this.checkCustomerDelinquency = this.checkCustomerDelinquency.bind(this);
    this.getTokenResponse = this.getTokenResponse.bind(this);
    this.getGuestTokenResponse = this.getGuestTokenResponse.bind(this);
    this.getPaymentOptions = this.getPaymentOptions.bind(this);
    this.getGuestPaymentOptions = this.getGuestPaymentOptions.bind(this);
    this.getGuestOneTimePayVerification = this.getGuestOneTimePayVerification.bind(
      this
    );

    this.getHost = this.getHost.bind(this);
    this.authConfig = {
      withCredentials: true
    };
  }

  getHost() {
    return location.allowedOrFallbackApiHost();
  }

  async getPaymentPrograms(id) {
    return axios.get(
      `${this.getHost()}/api/account/${id}/programCatalog`,
      this.authConfig
    );
  }

  async getPaymentCenterData(accounts) {
    const params = `accounts=${accounts.join(',')}`;
    return axios.get(
      `${this.getHost()}/api/PaymentCenterData?${params}`,
      this.authConfig
    );
  }

  async getGuestPayAccountDataByAccountNumber(params) {
    const { accountNumber, recaptchaToken } = params;
    return axios.get(
      `${this.getHost()}/api/guestPay?accountNumber=${accountNumber}&reCaptchaResponse=${recaptchaToken}`
    );
  }

  async getGuestOneTimePayVerification(params) {
    const { programName, recaptchaToken } = params;
    return axios.get(
      `${this.getHost()}/api/guestOneTimePayment?programName=${programName}&reCaptchaResponse=${recaptchaToken}`
    );
  }

  async getGuestPayAccountDataByAddress(params) {
    const { lastName, address, unit, zip, recaptchaToken } = params;
    return axios.get(
      `${this.getHost()}/api/guestPay?lastName=${lastName}&addressOne=${address}&addressTwo=${unit}&zipCode=${zip}&reCaptchaResponse=${recaptchaToken}`
    );
  }

  async postGuestPayment(body) {
    return axios.post(`${this.getHost()}/api/guestPay`, body);
  }

  async getSavedPaymentMethods(orgBp) {
    let endpoint = `${this.getHost()}/api/savedPaymentMethod`;
    if (orgBp) {
      endpoint += `?orgBp=${orgBp}`;
    }
    return axios.get(endpoint, this.authConfig);
  }

  async putSavedPaymentMethod(body) {
    return axios.put(
      `${this.getHost()}/api/savedPaymentMethod/${body.id}`,
      body,
      this.authConfig
    );
  }

  async deleteSavedPaymentMethod(params) {
    const { id, orgBp } = params;
    let endpoint = `${this.getHost()}/api/savedPaymentMethod/${id}`;
    if (orgBp) {
      endpoint += `?orgBp=${orgBp}`;
    }
    return axios.delete(endpoint, this.authConfig);
  }

  async postSavedPaymentMethod(body) {
    return axios.post(
      `${this.getHost()}/api/savedPaymentMethod`,
      body,
      this.authConfig
    );
  }

  async postAuthPayment(body) {
    return axios.post(`${this.getHost()}/api/payment`, body, this.authConfig);
  }

  async postBulkPayment(body) {
    return axios.post(
      `${this.getHost()}/api/bulkPayment`,
      body,
      this.authConfig
    );
  }

  async postSplitPayment(body) {
    return axios.post(
      `${this.getHost()}/api/splitPayment`,
      body,
      this.authConfig
    );
  }

  async getPaymentHistory(accountNumber) {
    const endpoint = `${this.getHost()}/api/account/${accountNumber}/paymentHistory`;
    return axios.get(endpoint, this.authConfig);
  }

  async getAccessToken() {
    return axios.post(
      `${this.getHost()}/api/accessToken`,
      paymetric.accessTokenPayload(),
      this.authConfig
    );
  }

  async getGuestAccessToken(paymentToken) {
    return axios.post(
      `${this.getHost()}/api/guest/accessToken`,
      paymetric.accessTokenPayload(paymentToken)
    );
  }

  async getTokenResponse(accessToken, merchantGuid) {
    return axios.post(
      `${this.getHost()}/api/tokenResponse`,
      { accessToken, merchantGuid },
      this.authConfig
    );
  }

  async getGuestTokenResponse(accessToken, merchantGuid, paymentToken) {
    return axios.post(`${this.getHost()}/api/guest/tokenResponse`, {
      accessToken,
      paymentToken,
      merchantGuid
    });
  }

  async getPaymentOptions(orgBp) {
    let endpoint = `${this.getHost()}/api/paymentOptions`;
    if (orgBp) {
      endpoint += `?orgBp=${orgBp}`;
    }
    return axios.get(endpoint, this.authConfig);
  }

  async getGuestPaymentOptions(paymentToken) {
    return axios.post(`${this.getHost()}/api/guest/paymentOptions`, {
      paymentToken
    });
  }

  async checkCustomerDelinquency(params) {
    const { businessId, premisesId } = params;
    const endpoint = `${this.getHost()}/api/mimo/checkCustomerDelinquency?businessID=${businessId}&premiseID=${premisesId}`;
    return axios.get(endpoint, this.authConfig);
  }
}
