import PropTypes from 'prop-types';

const getSplitPayAmount = ({ splitPaymentMethods }) => {
  if (splitPaymentMethods.length) {
    let total = 0;
    for (let item of splitPaymentMethods) {
      if (item.amount) {
        total += parseFloat(item.amount);
      }
    }
    return total.toString();
  }
  return '0.00';
};

getSplitPayAmount.propTypes = {
  splitPaymentMethods: PropTypes.array
};

export default getSplitPayAmount;
