import React from 'react';
import EditPaymentMethod from '../../../../components/EditPaymentMethod.jsx';
import DeletePaymentMethod from '../../../../components/DeletePaymentMethod.jsx';

import {
  UPDATE_SAVED_PAYMENT_METHOD,
  DELETE_SAVED_PAYMENT_METHOD,
  resetFailed
} from '../../../../actions';

import handleSubmitDelete from './handleSubmitDelete.js';
import handleSubmitEdit from './handleSubmitEdit.js';

const EditDelete = ({ enableEdit, enableDelete, paymentMethod, props }) => {
  const { dispatch, failed, loading, orgBp } = props;
  return (
    <div className="col-xs-4 col-sm-2 pull-xs-right">
      {enableEdit && (
        <EditPaymentMethod
          failed={failed[UPDATE_SAVED_PAYMENT_METHOD.name]}
          handleSubmit={values => handleSubmitEdit({ values, props })}
          loading={loading[UPDATE_SAVED_PAYMENT_METHOD.name]}
          orgBp={orgBp}
          paymentMethod={paymentMethod}
          resetError={() =>
            dispatch(resetFailed(UPDATE_SAVED_PAYMENT_METHOD.name))
          }
        />
      )}
      {enableDelete && (
        <DeletePaymentMethod
          failed={failed[DELETE_SAVED_PAYMENT_METHOD.name]}
          handleSubmit={id => handleSubmitDelete({ id, props })}
          loading={loading[DELETE_SAVED_PAYMENT_METHOD.name]}
          paymentMethod={paymentMethod}
        />
      )}
    </div>
  );
};
export default EditDelete;
