import PropTypes from 'prop-types';

const getTotalAmountDue = props => {
  const { customerDelinquency, data } = props;
  let totalAmountDue = data.totalAmountDue;
  if (Object.keys(customerDelinquency).length > 0) {
    totalAmountDue = customerDelinquency.delinquencyDetail.reduce(
      (total, i) => {
        if (i.amount) {
          return total + parseFloat(i.amount);
        }
        return total;
      },
      0
    );
  }
  return totalAmountDue;
};

getTotalAmountDue.propTypes = {
  customerDelinquency: PropTypes.object,
  data: PropTypes.object
};

export default getTotalAmountDue;
