import React from 'react';
import { formatDate } from '../../../../services/utils';
import PropTypes from 'prop-types';

const DateDisplay = props => {
  const { date } = props;

  return (
    <div className="col-xs-6 text-right">
      <div className="accordion-value">
        <span id="selected_payment_date" className="dte-greyish-blue">
          <span className="hidden-xs">{formatDate(date, true)}</span>
          <span className="visible-xs">{formatDate(date)}</span>
        </span>
      </div>
    </div>
  );
};

DateDisplay.propTypes = {
  date: PropTypes.object
};

export default DateDisplay;
