import moment from 'moment';
import creditCardType from 'credit-card-type';

import {
  NON_PAYMENT_CENTER_DATA_STRINGS,
  INVALID_ACCOUNT_STRINGS,
  apiHost
} from '../constants';

export const formatAccountNumber = accountNumber => {
  return /^(\d{4})(\d{3})(\d{4})(\d{1})$/
    .exec(accountNumber)
    .slice(1)
    .join(' ');
};

export const formatWithCapitalization = str => {
  let formattedStr = '';
  if (str.length) {
    for (let i = 0; i < str.length; i++) {
      if (i === 0 || str.charAt(i - 1) === ' ') {
        formattedStr += str.charAt(i);
        continue;
      }
      formattedStr += str.charAt(i).toLowerCase();
    }
  }
  return formattedStr;
};

export const formatFloat = value => {
  if (!value) {
    return 0;
  }

  // check for last value of string if it is a . or [0-9] it's okay otherwise reject or if greater than two decimal return fixed
  const regexNotNumeric = /[^\d|\.]/g;

  if (typeof value === 'number') {
    value = value.toString();
  }

  // check if value contains more than one decimal point
  if (((typeof value === 'string' && value.match(/\./g)) || []).length > 1) {
    const idx = value.indexOf('.');
    const front = value.slice(0, idx + 1);
    const back = value.slice(idx + 1).replace(/[^\d]/g);
    const result = (front + back).replace(regexNotNumeric, '');
    return result;
  }

  if (value[0] === '0' && value[1] !== '.') {
    const result = value.length > 1 ? value.slice(1) : 0;
    return result;
  }
  return value.replace(regexNotNumeric, '');
};

export const formatFloatToCurrency = (value, lessThanOne = false) => {
  let result;
  if (value.includes('.')) {
    const arr = value.split('.');
    const parsed = parseFloat(`${arr[0]}.${arr[1].slice(0, 2)}`);
    if (parsed >= 1 || lessThanOne) {
      result = parsed;
    } else {
      result = 0;
    }
  } else {
    result = parseFloat(value);
  }
  return result.toFixed(2);
};

// value is Number
export const formatMoney = value => {
  // check for toLocaleString support
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Checking_for_support_for_locales_and_options_arguments
  const supported = !!(
    typeof Intl == 'object' &&
    Intl &&
    typeof Intl.NumberFormat == 'function'
  );

  if (typeof value === 'string') {
    value = Number(value);
  }

  if (supported) {
    return value.toLocaleString('en', {
      style: 'currency',
      currency: 'USD'
    });
  } else {
    // https://stackoverflow.com/a/14428340/3614351
    const formatted = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return value >= 0 ? '$' + formatted : formatted.replace('-', '-$');
  }
};

export const formatDate = (dateString, includeDay = false) => {
  const parsedDate = moment.isMoment(dateString)
    ? dateString
    : moment(dateString);
  let displayString = includeDay ? parsedDate.format('dddd, ') : '';
  switch (parsedDate.month()) {
    case 0:
      displayString += 'Jan. ';
      break;
    case 1:
      displayString += 'Feb. ';
      break;
    case 2:
      displayString += 'March ';
      break;
    case 3:
      displayString += 'April ';
      break;
    case 4:
      displayString += 'May ';
      break;
    case 5:
      displayString += 'June ';
      break;
    case 6:
      displayString += 'July ';
      break;
    case 7:
      displayString += 'Aug. ';
      break;
    case 8:
      displayString += 'Sept. ';
      break;
    case 9:
      displayString += 'Oct. ';
      break;
    case 10:
      displayString += 'Nov. ';
      break;
    case 11:
      displayString += 'Dec. ';
      break;
  }
  displayString += parsedDate.format('D, YYYY');
  return displayString;
};

export const actionTypeDestructure = type => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  if (!matches) {
    return false;
  } else {
    const [, requestName, requestState] = matches;
    return {
      requestName,
      requestState
    };
  }
};

export const checkIfExpired = (paymentInfo, paymentDate) => {
  const date = paymentDate ? moment(paymentDate) : moment();
  const { expirationMonth, expirationYear } = paymentInfo.values
    ? paymentInfo.values
    : paymentInfo;
  if (parseInt(expirationYear) > date.year()) {
    return false;
  } else {
    if (
      parseInt(expirationYear) < date.year() ||
      parseInt(expirationMonth) - 1 < date.month()
    ) {
      return true;
    }
    return false;
  }
};

export const getPaymentObj = (
  accounts,
  date,
  total,
  splitPayment = false,
  oneTimePayment
) => {
  let url = !splitPayment ? '/api/payment' : '/api/splitPayment';
  if (accounts.length > 1) {
    url = '/api/bulkPayment';
  }

  const paymentObj = {
    payment: {
      accounts,
      date: moment(date).format('YYYY-MM-DD'),
      newPaymentMethods: [],
      savedPaymentMethods: [],
      totalPaymentAmount: total
    },
    url
  };

  if (oneTimePayment && oneTimePayment.programName) {
    const { firstName, lastName, programName } = oneTimePayment;
    paymentObj.oneTimePayment = {
      firstName: firstName,
      lastName: lastName,
      programName: programName
    };
  }

  return paymentObj;
};

export const getNewPaymentMethod = (kind, values, amount) => {
  let newPaymentMethod;
  if (kind) {
    const {
      cardToken,
      expirationMonth,
      expirationYear,
      securityCode,
      nameOnCard,
      savePayment,
      zipCode,
      country
    } = values;
    newPaymentMethod = {
      credit: {
        amount,
        cardToken,
        expirationMonth,
        expirationYear,
        cardCVV: securityCode,
        kind,
        nameOnCard,
        billingZipCode: zipCode,
        country,
        saveNewPaymentMethod: savePayment
      },
      id: 'newadd0',
      nickName: '',
      type: 'card'
    };
  } else {
    const {
      bankAccountNumber,
      bankRoutingNumber,
      kind,
      nameOnBankAccount,
      savePayment
    } = values;
    newPaymentMethod = {
      bankAccount: {
        amount,
        bankAccountNumber,
        bankRoutingNumber,
        kind,
        nameOnBankAccount,
        saveNewPaymentMethod: savePayment
      },
      id: 'newadd0',
      nickName: '',
      type: 'cheque'
    };
  }
  return newPaymentMethod;
};

export const getLastFourDigits = paymentMethod => {
  let lastFour;
  if (paymentMethod.lastFour) {
    lastFour = paymentMethod.lastFour.slice(-4);
  } else {
    if (paymentMethod.values.bankAccountNumber) {
      lastFour = paymentMethod.values.bankAccountNumber.slice(-4);
    } else {
      lastFour = paymentMethod.values.cardToken.slice(-4);
    }
  }
  return lastFour;
};

export const filterDelinquencyAccountsWithData = delinquency => {
  return delinquency.reduce((result, i) => {
    for (let matcher of NON_PAYMENT_CENTER_DATA_STRINGS) {
      if (i.advisementText.startsWith(matcher)) {
        return result;
      }
    }
    result.push(i);
    return result;
  }, []);
};

export const filterValidDelinquencyAccounts = delinquency => {
  return delinquency.reduce((result, i) => {
    for (let matcher of INVALID_ACCOUNT_STRINGS) {
      if (i.advisementText.startsWith(matcher)) {
        return result;
      }
    }
    result[i.contractAccount] = i;
    return result;
  }, {});
};

export const isCreditCard = inputValue => {
  const digitsAndSpaces = /^[\d\s]{15,}$/;
  if (digitsAndSpaces.exec(inputValue)) {
    if (creditCardType(inputValue).length > 0) {
      return true;
    }
  }
  return false;
};

export const isBankAccount = inputValue => {
  const iban = /^[A-Za-z]{2}\d{2,}$/;
  const us = /^\d{4,}$/;
  if (iban.exec(inputValue) || us.exec(inputValue)) {
    return true;
  }
  return false;
};

export const returnToNewlookUrl = url => {
  const origin = apiHost;
  if (url.indexOf('commercial') > -1) {
    return `${origin}/commercial/bill/index.jsp`;
  } else {
    return `${origin}/wps/wcm/connect/dte-web/home/billing-and-payments/residential/billing/current-bill`;
  }
};

export const isPrepayEnabled = () => {
  if (!PREPAY_ENABLED) return false;
  return PREPAY_ENABLED.toLowerCase() === 'true';
};

export const isQAAEnabled = () => {
  if (!QAA_ENABLED) return false;
  return QAA_ENABLED.toLowerCase() === 'true';
};
