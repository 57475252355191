import React from 'react';
import PropTypes from 'prop-types';

import getSplitPayAmount from './getSplitPaymentAmount';
import SplitPayHeader from './SplitPayHeader';
import InputAmountHeader from './InputAmountHeader';

const Header = props => {
  const {
    accountNumber,
    collapsed,
    disabled,
    handleChange,
    handleBlur,
    handleFocus,
    isPaymentEditable,
    paymentAmount,
    singleAccount,
    splitPay,
    splitPaymentMethods,
    totalAmount,
    values
  } = props;

  if (splitPay || !singleAccount || !isPaymentEditable) {
    let money;
    if (paymentAmount) {
      money = paymentAmount;
    } else {
      money = splitPay
        ? getSplitPayAmount({ splitPaymentMethods })
        : totalAmount;
    }

    return (
      <SplitPayHeader
        collapsed={collapsed}
        money={money}
        singleAccount={singleAccount}
      />
    );
  } else {
    return (
      <InputAmountHeader
        accountNumber={accountNumber}
        collapsed={collapsed}
        disabled={disabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        singleAccount={singleAccount}
        values={values}
      />
    );
  }
};

Header.propTypes = {
  accountNumber: PropTypes.string,
  collapsed: PropTypes.bool,
  customerDelinquency: PropTypes.object,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  isPaymentEditable: PropTypes.bool,
  getPaymentAmountInput: PropTypes.func,
  paymentAmount: PropTypes.string,
  programName: PropTypes.string,
  singleAccount: PropTypes.bool,
  splitPay: PropTypes.bool,
  splitPaymentMethods: PropTypes.array,
  totalAmount: PropTypes.string,
  values: PropTypes.object
};

export default Header;
