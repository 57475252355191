import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DateDisplay from './DateDisplay';

class PaymentDateReconnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      endDate: moment().add(60, 'days'),
      focused: true,
      startDate: moment()
    };
  }

  render() {
    const { collapsed } = this.props;
    const collapsedClass = collapsed ? 'collapsed' : '';

    const expandContractIcon = [
      `${collapsed ? 'expand' : 'contract'}-icon`
    ].join(' ');

    return (
      <div className="reconnect accordion-step panel border-borderGry">
        <div
          id="paymentDate-heading"
          className={`${collapsedClass} accordion-heading`}
        >
          <div className="row">
            <div className="col-xs-6">
              <div className={`accordion-state-icon accordionIcon`}>
                <span className={`${expandContractIcon} invisible hidden-xs`} />
              </div>
              <div className="accordion-label">
                <span className="total_amount_due">Payment Date</span>
              </div>
            </div>
            {<DateDisplay date={this.state.date} />}
          </div>
        </div>
      </div>
    );
  }
}

PaymentDateReconnect.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default PaymentDateReconnect;
