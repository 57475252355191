import React from 'react';
import PropTypes from 'prop-types';
import PaymentAmountInput from './PaymentAmountInput';
import { formatAccountNumber, formatMoney } from '../../../../services/utils';
import messages from './messages';

const Addresses = props => {
  const { data, isPaymentEditable, showMinimumAmountDue, values } = props;
  return data.accounts.map(i => {
    const { accountNumber, serviceAddress, minimumDue } = i;

    const value = values[accountNumber];

    return (
      <div
        key={accountNumber}
        className="row accordion-body-row underline-top-row accordion-left-well accordion-left-well-align padding-15px"
      >
        <div className="col-xs-8 col-sm-4 col-md-4">
          <span className="pull-left fontface-light customer-address pc-customer-address font-14px dte-bodyText-grey">
            {serviceAddress.address && (
              <>
                {serviceAddress.address}
                <br />
                {`${serviceAddress.city},
                ${serviceAddress.state} ${serviceAddress.zip}`}
                <br />
              </>
            )}
            <span className="account-number dte-bodyText-grey">
              {messages.accountNumberText} {formatAccountNumber(accountNumber)}
            </span>
          </span>
        </div>
        {showMinimumAmountDue && (
          <div className="col-xs-12 col-sm-5 col-md-5 font-sm due-date">
            {parseFloat(minimumDue) > 0 && (
              <>
                <span className="dte-emergency">
                  {messages.mimimumPaymentText}
                  {minimumDue}
                </span>
                <br />
              </>
            )}
          </div>
        )}
        {isPaymentEditable ? (
          <PaymentAmountInput
            accountNumber={accountNumber}
            value={value}
            {...props}
          />
        ) : (
          <div
            className="col-xs-4 col-md-8 text-right"
            style={{ fontSize: '1.375em', color: '#a1a1a4' }}
          >
            {formatMoney(value)}
          </div>
        )}
      </div>
    );
  });
};

Addresses.propTypes = {
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  getPaymentAmountInput: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  isPaymentEditable: PropTypes.bool,
  showInputAmountHeader: PropTypes.bool,
  showMinimumAmountDue: PropTypes.bool,
  values: PropTypes.object.isRequired
};

export default Addresses;
