import React from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

export const appDomId = 'app';

export const globalNameSpace = '_PC';

export const googleReCaptchaKey = `${GOOGLE_RECAPTCHA_SITE_KEY}`;

export const paymentTypes = {
  card: ['Discover', 'MasterCard', 'Visa'],
  cheque: ['Checking', 'Savings']
};

export const months = [
  '---',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export const years = (() => {
  const result = ['----'];
  const year = moment().year();
  for (let i = 0; i <= 20; i++) {
    result.push((year + i).toString());
  }
  return result;
})();

export const securityCodeMessage =
  'Please enter a valid 3-digit security code. ' +
  'It can be found on the back of your card.';

export const bankRoutingNumberTooltip =
  'Please enter a 9-digit bank routing number. You can locate it on the bottom left-hand corner of your checks.';

export const bankAccountNumberTooltip =
  'Please enter a bank account number that is 4-17 digits long.';

export const paymentNameTooltip =
  'Payment nickname is the name you provide for this payment method that will be easy to remember.';

export const COUNTRY_US_CODE = 'US';

export const countries = [
  { name: 'United States', code: COUNTRY_US_CODE },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D"Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People"S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People"S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const apiHost = `${API_HOST}`;
export const prepayURL = `${PREPAY_URL}`;
export const registrationURL = `${REGISTRATION_URL}`;
export const homePageRedirectURI = `${apiHost}${HOME_PAGE_REDIRECT}`;
export const loginPageRedirectURI = `${apiHost}${LOGIN_PAGE_REDIRECT}`;
export const mimoPageRedirectURI = `${apiHost}${MIMO_PAGE_REDIRECT}`;
export const paymentProgramsRedirectURI = `${apiHost}${PAYMENT_PROGRAMS_REDIRECT}`;
export const contactUsRedirectURI = `${apiHost}${CONTACT_US_REDIRECT}`;
export const paymentOptionsRedirectURI = `${apiHost}${PAYMENT_OPTIONS_REDIRECT}`;
export const autoPayRedirectURI = `${apiHost}${AUTOPAY_REDIRECT}`;
export const myPaymentMethodsRedirectURI = `${apiHost}${MY_PAYMENT_METHODS_REDIRECT}`;
export const paymetricHost = `${PAYMETRIC_DIECOMM_HOST}`;
export const paymetricXiPluginPath = `${PAYMETRIC_XIPLUGIN_PATH}`;
export const paymetricXiIFramePath = `${PAYMETRIC_XIFRAME_PATH}`;
export const paymetricCssPath = `/paymetric.css`;
export const billPdfURI = `${apiHost}${PDF_BILL_LINK}`;
export const currentBillRedirectURI = `${apiHost}${CURRENT_BILL_REDIRECT}`;
export const currentBillCommercialRedirectURI = `${apiHost}${CURRENT_BILL_COMMERCIAL_REDIRECT}`;
export const kioskLink = `${apiHost}/wps/wcm/connect/dte-web/home/billing-and-payments/common/billing/kiosks`;
export const paymentLocationsRedirect = `${apiHost}${PAYMENT_LOCATIONS_REDIRECT}`;
export const mimoHomePageURI = '/commercial/core-mimo/';
export const accountHistoryRedirectURI = `${apiHost}${ACCOUNT_HISTORY_REDIRECT}`;
export const profileAndPreferenceRedirectURI = `${apiHost}${PROFILE_AND_PREFERENCE_REDIRECT}`;
export const rebateStatusRedirectURI = `${apiHost}${REBATE_STATUS_REDIRECT}`;
export const myEnergyUsageLink = `${MY_ENERGY_USAGE_LINK}`;
export const outageCenterLink = `${OUTAGE_CENTER_LINK}`;
export const gasLeakTel = `${GAS_LEAK_TEL}`;
export const visitDteEnergyLink = `${VISIT_DTE_ENERGY_LINK}`;

const signInLink = (
  <a className="term-condition-link" href={loginPageRedirectURI}>
    <u>Sign in</u>
  </a>
);
const autoPayLink = (
  <a className="term-condition-link" href={autoPayRedirectURI}>
    <u>AutoPay</u>
  </a>
);
const customerContactNumber = (
  <a href="tel:1 (800) 477-4747" className="phone-number">
    800.477.4747
  </a>
);

export const securityMessage =
  'To ensure your personal information is safe and secure online, all transactions are encrypted and conducted on a secure server. ' +
  'This means communications between your computer and DTE Energy are converted to a secure and non-readable format for your protection.';

export const termsAndConditions = [
  <span key={uuidv4()}>
    It is your responsibility to ensure sufficient funds are available to
    complete any payments you schedule. You may be charged a returned item fee
    if funds are not available at the time of the scheduled payment.
  </span>,
  <span key={uuidv4()}>
    If your financial institution rejects the transaction for any reason, your
    payment will not be applied to your DTE Energy account.
  </span>,
  <span key={uuidv4()}>
    You may edit or cancel a scheduled one-time payment up to one day before the
    scheduled payment date. Same-day payments can be canceled, unless the
    payment has already been processed.
  </span>,
  <span key={uuidv4()}>
    If you are enrolling in {autoPayLink}, you are authorizing DTE Energy to
    debit the bank account or credit/debit card you designate each billing
    period to automatically pay the amount due on your billing statement. DTE
    Energy will advise you with a message on your billing statement of the
    amount and date of the payment to be automatically debited.
  </span>,
  <span key={uuidv4()}>To enroll in {autoPayLink}, you can:</span>
];

export const discontinueAutomaticPayments = [
  <span key={uuidv4()}>
    {signInLink} to your account and go to My Payment Programs.
  </span>,
  <span key={uuidv4()}>Call Customer Service at {customerContactNumber}.</span>,
  <span key={uuidv4()}>
    Write to Quality Control, 807 WCB, One Energy Plaza, Detroit, MI 48226. Your
    request must be received at least two business days before the scheduled
    debit date on your billing statement.
  </span>
];

export const autoreloadTermAndCondition = [
  <span key={uuidv4()}>
    By enrolling in the Automatic Reload, you are authorizing DTE Energy to
    debit your credit/debit card or bank account each time balance drops below
    agreed amount.
  </span>,
  <span key={uuidv4()}>
    You must make sure that sufficient funds are available to complete each
    Automatic reload or you may be charged a return item fee.
  </span>,
  <span key={uuidv4()}>
    If your financial institution rejects a transaction for any reason, your
    payment will not be applied to your DTE Energy account.
  </span>,
  <span key={uuidv4()}>
    <span key={uuidv4()}>
      To discontinue the Automatic Reload, you can sign in to your account at
      dteenergy.com for directions or call Customer Service at{' '}
      {customerContactNumber}.
    </span>
  </span>
];

export const PATH_NAME = {
  ADD: '/add-payment-method',
  AUTH: '/pay-bill',
  CONFRIM: '/pay-bill/confirm',
  CONFRIM_PAYMENT_AGREEMENT: '/pay-bill/confirm-payment-agreement',
  GUEST: '/guest-pay',
  GUEST_ONE_TIME_PAYMENT: '/guest-one-time-payment',
  MIMO: '/mimo',
  PAYMENT_AGREEMENT: '/pay-bill/payment-agreement',
  PAYMENT_EXTENSION: '/pay-bill/payment-extension',
  RECONNECT: '/pay-bill/reconnect',
  ONE_TIME_PAYMENT: '/one-time-payment',
  PREPAY: '/prepay'
};

export const masks = {
  accountNumber: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/
  ],
  zipCode: {
    us: [/\d/, /\d/, /\d/, /\d/, /\d/],
    intl: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/]
  },
  securityCode: [/\d/, /\d/, /\d/],
  creditCard: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]
};

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationErrors = {
  nameOnBankAccount: 'Please enter name on bank account.',
  nameOnCard: 'Please enter name on card.',
  paymentNickname:
    'Payment nickname can only contain letters of the alphabet, periods (.) and hyphens (-).',
  zipCode: 'Please enter a valid ZIP Code.',
  expMonth: 'Please select a valid expiration month.',
  expYear: 'Please select a valid expiration year.',
  expDate: 'Please select a valid expiration date.',
  email: 'Please provide a valid email address.',
  securityCode:
    'Please enter the 3-digit security code on your credit or debit card.',
  routingNumber: 'Please enter your 9-digit routing number.',
  bankAccountNumber: 'Please enter your bank account number.',
  validateBankAccountNumber: 'Please re-enter your bank account number.',
  serviceAccountNumber: 'Please enter a 12-digit account number.',
  lastName: 'Please enter a valid last name or business name.',
  address: 'Please enter a valid street address.'
};

export const INVALID_ACCOUNT_STRINGS = [
  'Contract Account - Bankruptcy',
  'Contract Account - Sold Account'
  // 'Contract Account - Write off'
];

export const NON_PAYMENT_CENTER_DATA_STRINGS = [
  'Security deposit',
  'Contract Account - Bankruptcy',
  'Contract Account - Sold Account'
];
