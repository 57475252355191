import React from 'react';
import { apiHost } from '../../../../constants';
import messages from './messages';

const Disclaimer = ({ paymentProgram, programName }) => {
  if (paymentProgram === 'true') {
    return (
      <div className="row pc-accordion-body-row padding-bottom-0">
        <div className="hidden-xs col-sm-1" />
        <div className="col-xs-12 col-sm-7 pymnt-static-text-style dte-bodyText-grey">
          {!programName && (
            <p>
              {messages.autoPayDisclaimerText1}{' '}
              <a
                href={`${apiHost}/wps/wcm/connect/dte-web/profile-center/payment-programs`}
                title="multi_acct_autopaylink"
                className="link-text"
              >
                {messages.autoPayDisclaimerText2}
              </a>{' '}
              {messages.autoPayDisclaimerText3}
            </p>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Disclaimer;
