import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Addresses from './Addresses.jsx';
import Header from './Header.jsx';

class PaymentAmount extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      className,
      collapsed,
      customerDelinquency,
      data,
      disabled,
      handleChange,
      handleBlur,
      handleFocus,
      isPaymentEditable,
      paymentAmount,
      showInputAmountHeader,
      showMinimumAmountDue,
      splitPay,
      splitPaymentMethods,
      toggle,
      values
    } = this.props;
    const singleAccount = data.accounts.length === 1;

    const collapsedClass =
      (collapsed && !singleAccount) || singleAccount ? 'collapsed ' : '';
    let accountNumber;
    if (singleAccount) {
      const singleAccountNumber = data.accounts[0].accountNumber;
      accountNumber = data.accounts.find(
        i => i.accountNumber === singleAccountNumber
      ).accountNumber;
    }

    const totalAmount = Object.keys(values)
      .reduce((result, accountNumber) => {
        const stored = values[accountNumber] || 0;
        const val = stored !== '.' ? parseFloat(stored) : 0;
        return result + val;
      }, 0)
      .toFixed(2);

    return (
      <div
        className={`${className} accordion-step panel border-gray-top-0 accordion-border`}
      >
        <div
          id="paymentAmount-heading"
          className={`${collapsedClass}accordion-heading`}
          onClick={() => toggle('paymentAmount')}
        >
          <div className="row">
            {
              <Header
                accountNumber={accountNumber}
                customerDelinquency={customerDelinquency}
                disabled={disabled}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
                isPaymentEditable={isPaymentEditable}
                paymentAmount={paymentAmount}
                singleAccount={singleAccount}
                showInputAmountHeader={showInputAmountHeader}
                splitPay={splitPay}
                splitPaymentMethods={splitPaymentMethods}
                totalAmount={totalAmount}
                values={values}
              />
            }
          </div>
        </div>
        {!collapsed && !singleAccount && (
          <Addresses
            data={data}
            disabled={disabled}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            isPaymentEditable={isPaymentEditable}
            showInputAmountHeader={showInputAmountHeader}
            showMinimumAmountDue={showMinimumAmountDue}
            values={values}
          />
        )}
      </div>
    );
  }
}

PaymentAmount.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  customerDelinquency: PropTypes.object,
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  isPaymentEditable: PropTypes.bool,
  paymentAgreement: PropTypes.object,
  paymentAmount: PropTypes.string,
  showInputAmountHeader: PropTypes.bool,
  showMinimumAmountDue: PropTypes.bool,
  splitPay: PropTypes.bool,
  splitPaymentMethods: PropTypes.array,
  toggle: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default PaymentAmount;
