import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';

import { DELETE_SAVED_PAYMENT_METHOD, resetFailed } from '../actions';

const gtmDeletePayment = {
  dataTrack: 'payments',
  dataTrackDetail: 'authenticated',
  dataTrackAction: 'payment method',
  dataTrackSubAction: 'delete'
};

const gtmDeleteConfirmPayment = {
  dataTrack: 'payments',
  dataTrackDetail: 'authenticated',
  dataTrackAction: 'payment method',
  dataTrackSubAction: 'delete submit'
};

class DeletePaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loading &&
      !this.props.loading &&
      this.props.failed &&
      !this.props.failed.state
    ) {
      this.handleCloseModal();
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.props.dispatch(resetFailed(DELETE_SAVED_PAYMENT_METHOD.name));
  }

  errorMessage() {
    const { failed } = this.props;
    if (failed && failed.state) {
      return <div className="modal-error-message">{failed.message}</div>;
    }
  }

  display() {
    const { paymentMethod } = this.props;
    const { nickName, lastFour } = paymentMethod;
    return (
      <div className="row">
        <div className="col-xs-12 padding-top-15 dte-bodyText-grey">
          {nickName} {lastFour}
        </div>
      </div>
    );
  }

  render() {
    const { paymentMethod, handleSubmit, loading, failed } = this.props;
    const disableDeleteBtn = (failed && failed.state) || loading;
    const disabledClass = disableDeleteBtn ? 'disabled ' : '';
    return (
      <Fragment>
        <a
          href="#"
          onClick={this.handleOpenModal}
          className="payment-action"
          data-track={gtmDeletePayment.dataTrack}
          data-track-detail={gtmDeletePayment.dataTrackDetail}
          data-track-action={gtmDeletePayment.dataTrackAction}
          data-track-sub-action={gtmDeletePayment.dataTrackSubAction}
        >
          Delete
        </a>
        <ReactModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          className="modal-dialog modal-lg"
          overlayClassName="modal-backdrop fade in react-modal-overlay"
        >
          <div className="">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  type="button"
                  onClick={this.handleCloseModal}
                >
                  <span className="icon-close" />
                </button>
                <h4 className="modal-title">Delete Payment Method</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="dte-h3">
                      Are you sure you want to delete this payment method?
                    </div>
                  </div>
                </div>
                {this.display()}
                <div className="row padding-top-md">
                  {this.errorMessage()}
                  <div className="col-xs-12 col-md-4 col-md-offset-2 margin-bottom-15px text-right-md text-center padding-right-md-10px">
                    <button
                      className="btn cancel-btn dte-btn"
                      type="button"
                      onClick={this.handleCloseModal}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-xs-12 col-md-4 margin-bottom-15px text-left-md text-center padding-left-md-10px">
                    <button
                      className={`${disabledClass}btn dte-btn`}
                      type="button"
                      disabled={disableDeleteBtn}
                      data-track={gtmDeleteConfirmPayment.dataTrack}
                      data-track-detail={
                        gtmDeleteConfirmPayment.dataTrackDetail
                      }
                      data-track-action={
                        gtmDeleteConfirmPayment.dataTrackAction
                      }
                      data-track-sub-action={
                        gtmDeleteConfirmPayment.dataTrackSubAction
                      }
                      onClick={() => handleSubmit(paymentMethod.id)}
                    >
                      {loading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        'Delete'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    );
  }
}

DeletePaymentMethod.propTypes = {
  dispatch: PropTypes.func,
  paymentMethod: PropTypes.object,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.object
};

export default connect()(DeletePaymentMethod);
