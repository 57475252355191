import {
  resetRecaptcha,
  getGuestOneTimePayVerification,
  resetRecaptchaToken,
  setRecaptchaToken
} from '../../../../actions';

const reCaptchaCallback = ({ token, props }) => {
  const {
    dispatch,
    oneTimePayment: { programName }
  } = props;

  dispatch(resetRecaptcha());

  if (!token) {
    dispatch(resetRecaptchaToken());
  }

  if (token) {
    dispatch(setRecaptchaToken(token));

    const data = {
      programName: programName,
      recaptchaToken: token
    };

    dispatch(getGuestOneTimePayVerification(data));
  }
};
export default reCaptchaCallback;
