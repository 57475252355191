import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Addresses from './Addresses';
import messages from './messages';

const MultipleAccountAmountDue = props => {
  const { collapsed, data, singleAccount, toggle } = props;

  const collapsedClass =
    (collapsed && !singleAccount) || singleAccount ? 'collapsed ' : '';

  const expandContractIcon = [
    `${collapsed ? 'expand' : 'contract'}-icon`,
    `${singleAccount ? 'invisible' : ''}`
  ].join(' ');

  return (
    <Fragment>
      <div
        id="amountDue-heading"
        className={`${collapsedClass}accordion-heading borderBtm`}
        onClick={() => toggle('amountDue')}
      >
        <div className="row">
          <div className="col-xs-8">
            <div className="accordion-state-icon accordionIcon">
              <span className={expandContractIcon} />
            </div>
            <div className="accordion-label">{messages.accountSummaryText}</div>
          </div>
        </div>
      </div>
      {!collapsed && <div>{<Addresses data={data} />}</div>}
    </Fragment>
  );
};

MultipleAccountAmountDue.propTypes = {
  collapsed: PropTypes.bool,
  data: PropTypes.object,
  singleAccount: PropTypes.bool,
  toggle: PropTypes.func
};

export default MultipleAccountAmountDue;
