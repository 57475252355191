const showPaymentMethod = ({ item, showLastFour = true }) => {
  if (!item.nickName) {
    if (item.values && item.values.bankAccountNumber) {
      return `****${item.values.bankAccountNumber.slice(-4)}`;
    }
    return item.values ? item.values.lastFour : item.lastFour;
  } else {
    const nicknameLen = item.nickName.length;
    const nickName =
      nicknameLen <= 10 ? item.nickName : `${item.nickName.slice(0, 10)}...`;
    return `${nickName} ${showLastFour ? item.lastFour : ''}`;
  }
};

export default showPaymentMethod;
