import React from 'react';
import getCcIcon from './getCcIcon';

const PaymentIcon = ({ paymentObject }) => {
  const icon = getCcIcon({ kind: paymentObject.kind, isAccordionHeader: true });
  if (icon) {
    return (
      <span>
        <div className="cc-icon-wrapper">
          <img className="card-logo-img-pm" src={icon} />
        </div>{' '}
      </span>
    );
  } else {
    return null;
  }
};

export default PaymentIcon;
