import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from '../../../../services/utils';
import Icon from './Icon';

const SplitPayHeader = props => {
  const { collapsed, money, singleAccount } = props;

  return (
    <Fragment>
      <div className="col-xs-6">
        <Icon collapsed={collapsed} singleAccount={singleAccount} />
      </div>
      <div className="text-right col-xs-6">
        <div className="accordion-value">
          <span id="totalDueAllAccounts" className="currency">
            {formatMoney(money)}
          </span>
        </div>
      </div>
    </Fragment>
  );
};

SplitPayHeader.propTypes = {
  collapsed: PropTypes.bool,
  money: PropTypes.string,
  singleAccount: PropTypes.bool
};

export default SplitPayHeader;
