import React from 'react';
import { render } from 'react-dom';
import 'jquery';
import 'bootstrap';

import './styles/index.scss';

import Root from './containers/Root.jsx';
import { appDomId } from './constants';
// import { iniatlizeGoogleTagManager } from 'utils/analytics';

let gtmScript;
let iframe;

// iniatlizeGoogleTagManager();

const pushAnalytics = params => {
  if (window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push(params);
  }
};

const pushError = (maskedUri, method, status) =>
  pushAnalytics({
    event: 'webServiceErrors',
    eventCategory: 'Service Errors',
    eventAction: method,
    eventLabel: JSON.stringify([
      {
        [maskedUri]: status
      }
    ])
  });

const maskUri = uri => uri.replace(/[0-9]/g, 'X');

/**
 * Tracks the results of a service call in Google Analytics.
 * @param {string} uri The service URI.
 * @param {string} method The HTTP method.
 * @param {Response} response A Fetch response object.
 */
export const trackServiceResult = (uri, method, response) => {
  const maskedUri = maskUri(uri);

  if (response.status === 200) {
    if (apiMatch.test(uri)) {
      pushAnalytics({
        event: 'successAPI',
        eventCategory: maskedUri.split('?')[0],
        eventAction: method
      });
    }
  } else {
    pushError(maskedUri, method, response.status);
  }
};

/**
 * Tracks service call timeouts in Google Analytics.
 * @param {string} uri The service URI.
 * @param {string} method The HTTP method.
 */
export const trackServiceTimeout = (uri, method) => {
  const maskedUri = maskUri(uri);

  pushError(maskedUri, method, 'Timeout');
};

if (GTM_AUTH && GTM_PREVIEW) {
  gtmScript = `(function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      '&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x';
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${GTM_ID}');`;

  iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}|&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_PREVIEW}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
} else {
  gtmScript = `(function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${GTM_ID}');`;

  iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}|" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
}

const scriptElement = document.createElement('script');
scriptElement.innerHTML = gtmScript;
document.head.appendChild(scriptElement);

const dynataraceScriptElement = document.createElement('script');
dynataraceScriptElement.type = 'text/javascript';
dynataraceScriptElement.src = `${DYNATRACE_SCRIPT}`;
if (DYNATRACE_CONFIG) {
  dynataraceScriptElement.setAttribute('data-dtconfig', `${DYNATRACE_CONFIG}`);
  dynataraceScriptElement.setAttribute('crossorigin', 'anonymous');
}
document.head.appendChild(dynataraceScriptElement);
document.head.appendChild(dynataraceScriptElement);

const noScriptElement = document.createElement('noscript');
noScriptElement.innerHTML = iframe;
document.body.appendChild(noScriptElement);

render(<Root />, document.getElementById(appDomId));
