export function createRequestTypes(base, name) {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((acc, type) => {
    acc[type] = `${base}::${name}_${type}`;
    acc.name = `${base}::${name}`;
    return acc;
  }, {});
}

function makeActionCreator(type, ...argNames) {
  return function(...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      if (args[index]) {
        action[argNames[index]] = args[index];
      }
    });
    return action;
  };
}

export const GET_PAYMENT_PROGRAMS = createRequestTypes(
  'GET',
  'PAYMENT_PROGRAMS'
);
export const getPaymentProgramsData = makeActionCreator(
  GET_PAYMENT_PROGRAMS.REQUEST,
  'accounts'
);

export const GET_PAYMENT_CENTER_DATA = createRequestTypes(
  'GET',
  'PAYMENT_CENTER_DATA'
);
export const getPaymentCenterData = makeActionCreator(
  GET_PAYMENT_CENTER_DATA.REQUEST,
  'data'
);

export const setRooftopSolarData = makeActionCreator(
  GET_PAYMENT_CENTER_DATA.SUCCESS,
  'data'
);

export const GET_SAVED_PAYMENT_METHODS = createRequestTypes(
  'GET',
  'SAVED_PAYMENT_METHODS'
);
export const getSavedPaymentMethods = makeActionCreator(
  GET_SAVED_PAYMENT_METHODS.REQUEST,
  'orgBp'
);

export const GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER = createRequestTypes(
  'GET',
  'GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER'
);
export const getGuestPayAccountDataByAccountNumber = makeActionCreator(
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ACCOUNT_NUMBER.REQUEST,
  'accounts'
);

export const GET_GUEST_ONE_TIME_PAY_VERIFICATION = createRequestTypes(
  'GET',
  'GUEST_ONE_TIME_PAY_VERIFICATION'
);
export const getGuestOneTimePayVerification = makeActionCreator(
  GET_GUEST_ONE_TIME_PAY_VERIFICATION.REQUEST,
  'params'
);

export const GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS = createRequestTypes(
  'GET',
  'GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS'
);
export const getGuestPayAccountDataByAddress = makeActionCreator(
  GET_GUEST_PAY_ACCOUNT_DATA_BY_ADDRESS.REQUEST,
  'accounts'
);

export const POST_GUEST_PAYMENT = createRequestTypes('POST', 'GUEST_PAYMENT');
export const postGuestPayment = makeActionCreator(
  POST_GUEST_PAYMENT.REQUEST,
  'paymentDetails'
);

export const POST_AUTH_PAYMENT = createRequestTypes('POST', 'AUTH_PAYMENT');
export const postAuthPayment = makeActionCreator(
  POST_AUTH_PAYMENT.REQUEST,
  'paymentDetails',
  'paymentMethod'
);

export const POST_BULK_PAYMENT = createRequestTypes('POST', 'BULK_PAYMENT');
export const postBulkPayment = makeActionCreator(
  POST_BULK_PAYMENT.REQUEST,
  'paymentDetails',
  'paymentMethod'
);

export const POST_SPLIT_PAYMENT = createRequestTypes('POST', 'SPLIT_PAYMENT');
export const postSplitPayment = makeActionCreator(
  POST_SPLIT_PAYMENT.REQUEST,
  'paymentDetails',
  'paymentMethods'
);

export const RESET_SEARCH_FORM = 'RESET_SEARCH_FORM';
export const resetSearchForm = makeActionCreator(RESET_SEARCH_FORM);

export const UPDATE_SAVED_PAYMENT_METHOD = createRequestTypes(
  'UPDATE',
  'SAVED_PAYMENT_METHOD'
);
export const updateSavedPaymentMethod = makeActionCreator(
  UPDATE_SAVED_PAYMENT_METHOD.REQUEST,
  'item'
);

export const DELETE_SAVED_PAYMENT_METHOD = createRequestTypes(
  'DELETE',
  'SAVED_PAYMENT_METHOD'
);
export const deleteSavedPaymentMethod = makeActionCreator(
  DELETE_SAVED_PAYMENT_METHOD.REQUEST,
  'params'
);

export const POST_SAVED_PAYMENT_METHOD = createRequestTypes(
  'POST',
  'SAVED_PAYMENT_METHOD'
);
export const postSavedPaymentMethod = makeActionCreator(
  POST_SAVED_PAYMENT_METHOD.REQUEST,
  'paymentInfo'
);

export const ADD_UNSAVED_PAYMENT_METHOD = 'ADD_UNSAVED_PAYMENT_METHOD';
export const addUnsavedPaymentMethod = makeActionCreator(
  ADD_UNSAVED_PAYMENT_METHOD
);

export const RESET_RECAPTCHA = 'RESET_RECAPTCHA';
export const resetRecaptcha = makeActionCreator(RESET_RECAPTCHA);

export const RESET_RECAPTCHA_TOKEN = 'RESET_RECAPTCHA_TOKEN';
export const resetRecaptchaToken = makeActionCreator(RESET_RECAPTCHA_TOKEN);

export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN';
export const setRecaptchaToken = makeActionCreator(
  SET_RECAPTCHA_TOKEN,
  'token'
);

export const RESET_FAILED = 'RESET_FAILED';
export const resetFailed = makeActionCreator(RESET_FAILED, 'key');

export const GET_PAYMENT_HISTORY = createRequestTypes('GET', 'PAYMENT_HISTORY');
export const getPaymentHistory = makeActionCreator(
  GET_PAYMENT_HISTORY.REQUEST,
  'accounts'
);

export const GET_ACCESS_TOKEN = createRequestTypes('GET', 'ACCESS_TOKEN');
export const getAccessToken = makeActionCreator(GET_ACCESS_TOKEN.REQUEST);

export const GET_GUEST_ACCESS_TOKEN = createRequestTypes(
  'GET',
  'GUEST_ACCESS_TOKEN'
);
export const getGuestAccessToken = makeActionCreator(
  GET_GUEST_ACCESS_TOKEN.REQUEST,
  'paymentToken'
);

export const CLEAR_ACCESS_TOKEN = 'CLEAR_ACCESS_TOKEN';
export const clearAccessToken = makeActionCreator(CLEAR_ACCESS_TOKEN);

export const CLEAR_TOKEN_RESPONSE = 'CLEAR_TOKEN_RESPONSE';
export const clearTokenResponse = makeActionCreator(CLEAR_TOKEN_RESPONSE);

export const GET_TOKEN_RESPONSE = createRequestTypes('GET', 'TOKEN_RESPONSE');
export const getTokenResponse = makeActionCreator(
  GET_TOKEN_RESPONSE.REQUEST,
  'accessToken',
  'merchantGuid',
  'paymentToken'
);

export const CHECK_CUSTOMER_DELINQUENCY = createRequestTypes(
  'CHECK',
  'CUSTOMER_DELINQUENCY'
);

export const checkCustomerDelinquency = makeActionCreator(
  CHECK_CUSTOMER_DELINQUENCY.REQUEST,
  'params'
);

export const GET_PAYMENT_OPTIONS = createRequestTypes('GET', 'PAYMENT_OPTIONS');
export const getPaymentOptions = makeActionCreator(
  GET_PAYMENT_OPTIONS.REQUEST,
  'orgBp'
);
