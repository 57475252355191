import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import messages from './messages';

const Icon = props => {
  const { collapsed, singleAccount } = props;
  const hideClass = singleAccount ? 'invisible hidden-xs ' : '';
  const paddingTop = singleAccount ? 'padding-top-6px ' : '';
  let icon;
  if (!collapsed) {
    icon = <span className={`${hideClass}expand-icon`} />;
  } else {
    icon = <span className={`${hideClass}contract-icon`} />;
  }
  return (
    <Fragment>
      <div className="accordion-state-icon accordionIcon">{icon}</div>
      <div className={`${paddingTop}accordion-label mobileNameAmt`}>
        {messages.paymentAmountHeaderText}
      </div>
    </Fragment>
  );
};

Icon.propTypes = {
  collapsed: PropTypes.bool,
  singleAccount: PropTypes.bool
};

export default Icon;
