import React from 'react';
import PropTypes from 'prop-types';

const PaymentAmountInput = props => {
  const {
    accountNumber,
    disabled = false,
    handleBlur,
    handleChange,
    handleFocus,
    showInputAmountHeader,
    singleAccount,
    value,
  } = props;
  let positionClass;
  if (showInputAmountHeader) {
    positionClass = 'singlePaymentInput pull-right';
  } else if (singleAccount) {
    positionClass =
      'col-xs-12 col-sm-6 col-md-4 singlePaymentInput padding-right-0';
  } else {
    positionClass =
      'col-xs-12 col-sm-6 col-md-4 singlePaymentInput padding-right-0';
  }
  console.log('Before checking total amount due less than 0:', value);
  const amountValue = value < 0 ? 0.0 : value;
  console.log('After checking total amount due less than 0:', value);

  return (
    <>
      <div className={positionClass}>
        <div className="payment-input text-right">
          <div className="input-group">
            {!disabled && (
              <span className="input-group-addon input-select-payment">$</span>
            )}
            <input
              className="blackDrkImp form-control small-input text-input-height text-right accordion-amt-value currency pc-currency"
              disabled={disabled}
              name={`paymentAmount.${accountNumber}`}
              onBlur={e => handleBlur(e, accountNumber)}
              onChange={e => handleChange(e, accountNumber)}
              onFocus={() => handleFocus(accountNumber)}
              type="text"
              value={amountValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

PaymentAmountInput.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  showInputAmountHeader: PropTypes.bool
};

export default PaymentAmountInput;
