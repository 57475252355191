import {
  paymetricHost,
  paymetricXiPluginPath,
  paymetricXiIFramePath,
  paymetricCssPath
} from '../constants.js';

export const iframeUrl = (
  accessToken,
  merchantGuid,
  bubbleUpToParentWindow
) => {
  let bubble = 'False';
  if (bubbleUpToParentWindow === true) {
    bubble = 'True';
  }
  return `${paymetricHost}/view/iframe/${merchantGuid}/${accessToken}/${bubble}`;
};

export const xiPluginUrl = () => {
  return `${paymetricHost}${paymetricXiPluginPath}`;
};

export const xiIFramePluginUrl = () => {
  return `${paymetricHost}${paymetricXiIFramePath}`;
};

export const accessTokenPayload = paymentToken => {
  const payload = {
    cssPath: paymetricCssPath
  };
  if (paymentToken !== undefined) {
    payload.paymentToken = paymentToken;
  }
  return payload;
};
