import { updateSavedPaymentMethod } from '../../../../actions';

const handleSubmitEdit = ({ values, props }) => {
  const { orgBp } = props;
  const {
    accountHolderName,
    country,
    expirationMonth,
    expirationYear,
    id,
    kind,
    lastFour,
    nickName,
    routingNumber,
    type,
    zipCode
  } = values;

  let item = {
    accountHolderName,
    id,
    kind,
    lastFour,
    nickName,
    type,
    url: '/api/savedPaymentMethod'
  };
  if (orgBp) {
    item.orgBp = parseInt(orgBp);
  }
  if (type === 'card') {
    item.expirationMonth = expirationMonth;
    item.expirationYear = expirationYear;
    item.billingZipCode = zipCode;
    item.country = country;
  }
  if (type === 'cheque') {
    item.routingNumber = routingNumber;
  }
  props.dispatch(updateSavedPaymentMethod(item));
};

export default handleSubmitEdit;
