import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import ReactModal from 'react-modal';
import moment from 'moment';
import MaskedInput from 'react-text-mask';

import discoverSvg from '../../static/Discover.svg';
import visaSvg from '../../static/Visa2.svg';
import mastercardSvg from '../../static/Mastercard.svg';
import closeSvg from '../../static/Active-Close-Icon.svg';

import { isCreditCard, isBankAccount } from '../services/utils';

import {
  paymentTypes,
  months,
  years,
  masks,
  countries,
  COUNTRY_US_CODE,
  paymentNameTooltip,
  bankRoutingNumberTooltip,
  bankAccountNumberTooltip,
  validationErrors,
  appDomId
} from '../constants';

const gtmEditPayment = {
  dataTrack: 'payments',
  dataTrackDetail: 'authenticated',
  dataTrackAction: 'payment method',
  dataTrackSubAction: 'edit'
};

const gtmEditSavePayment = {
  dataTrack: 'payments',
  dataTrackDetail: 'authenticated',
  dataTrackAction: 'payment method',
  dataTrackSubAction: 'edit save'
};

class EditPaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.validate = this.validate.bind(this);
    this.creditCard = this.creditCard.bind(this);
    this.cheque = this.cheque.bind(this);
    this.enablePopovers = this.enablePopovers.bind(this);
    this.tooltips = new Map();
    this.tooltipOptions = {
      trigger: 'hover',
      container: 'body',
      placement: 'top'
    };
  }

  enablePopovers() {
    this.setupTooltips();
  }

  setupTooltips() {
    const container = $(`#${appDomId}, .ReactModal__Overlay`);
    const tooltips = this.tooltips;
    // Function must be named to use with off.
    function handleClose() {
      $(tooltips[container.data('tt')]).tooltip('hide');
      container.removeData('tt');
    }
    for (let ttName in this.tooltips) {
      $(tooltips[ttName])
        .tooltip(this.tooltipOptions)
        .on('shown.bs.tooltip', () => {
          container.data('tt', ttName).on('hover', handleClose);
        })
        .on('hide.bs.tooltip', () => {
          container.off('hover', handleClose);
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.loading &&
      !this.props.loading &&
      this.props.failed &&
      !this.props.failed.state
    ) {
      this.handleCloseModal();
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.props.resetError();
    this.setState({ showModal: false });
  }

  getCountryList() {
    return countries.map(country => {
      return (
        <option key={country.code} value={country.code}>
          {country.name}
        </option>
      );
    });
  }

  getTooltip(name, content) {
    let classNames = [
      'edit-tooltip-div',
      'padding-left0 margin-top-md',
      'padding-right-0'
    ];

    if (content === paymentNameTooltip) {
      classNames.push('payment-name-tooltip');
    }

    if (name === 'cpn-tt') {
      classNames = ['payment-name-tooltip'];
    }

    return (
      <div className={classNames.join(' ')}>
        <span className="input-group-btn">
          <a
            className="infoIcon margin-leftNeg tooltip-icon-input-group-edit"
            title={content}
            ref={ref => {
              this.tooltips[name] = ref;
            }}
          />
        </span>
      </div>
    );
  }

  getCardIconSrc(kind) {
    switch (kind) {
      case 'Discover':
        return discoverSvg;
      case 'Visa':
        return visaSvg;
      case 'MasterCard':
        return mastercardSvg;
      default:
        return '';
    }
  }

  validate(values) {
    let errors = {};
    const { accountHolderName, nickName } = values;
    const paymentNameValidator = /^[A-Za-z-. ]*$/g;
    const nameValidator = /^[ -~]*$/g;

    if (
      !accountHolderName ||
      !nameValidator.test(accountHolderName) ||
      isCreditCard(accountHolderName) ||
      isBankAccount(accountHolderName)
    ) {
      errors.accountHolderName =
        values.type === 'card'
          ? validationErrors.nameOnCard
          : validationErrors.nameOnBankAccount;
    }

    if (
      !nickName ||
      !paymentNameValidator.test(nickName) ||
      nickName.length > 45 ||
      isCreditCard(nickName)
    ) {
      errors.nickName = validationErrors.paymentNickname;
    }

    if (values.type === 'card') {
      const { expirationMonth, expirationYear, zipCode } = values;

      const zip = zipCode.replace(/_/g, '');

      if (!zip) {
        errors.zipCode = validationErrors.zipCode;
      }

      if (values.country === 'US' && zip.length < 5) {
        errors.zipCode = validationErrors.zipCode;
      }

      if (expirationMonth === months[0]) {
        errors.expirationMonth = validationErrors.expMonth;
      }

      if (expirationYear === years[0]) {
        errors.expirationYear = validationErrors.expYear;
      }

      if (expirationYear !== years[0] && expirationMonth !== months[0]) {
        const startOfMonth = moment().startOf('month');
        const expiredDate = moment(`${expirationYear}-${expirationMonth}-01`);
        if (moment(expiredDate).isBefore(startOfMonth)) {
          errors.expirationMonth = validationErrors.expDate;
        }
      }
    }
    return errors;
  }

  creditCard() {
    const { paymentMethod, handleSubmit, loading } = this.props;
    const {
      id,
      kind,
      lastFour,
      accountHolderName,
      expirationYear,
      country,
      nickName,
      expirationMonth
    } = paymentMethod;
    const cardIconSrc = this.getCardIconSrc(kind);
    const expMonth =
      expirationMonth.length === 1 ? `0${expirationMonth}` : expirationMonth;
    return (
      <Fragment>
        <div className="credit-card-wrapper">
          <h5 className="text-center payment-method-header margin-bottom-24px">
            <b>Credit/Debit Card</b>
          </h5>
          {cardIconSrc !== '' ? (
            <div className="col-xs-12">
              <img
                className="card-logo-img"
                src={cardIconSrc}
                alt="CC"
                style={{
                  width: '49px',
                  height: '30px'
                }}
              />
            </div>
          ) : null}
          {/*         <p className="required-field-text"> */}
          {/*           <span className="asterisk-required">*</span> */}
          {/*           <span id="displayAccountNumberInfo">Required Field</span> */}
          {/*         </p> */}
          <Formik
            initialValues={{
              id,
              type: 'card',
              kind,
              lastFour,
              accountHolderName,
              expirationMonth: expMonth,
              expirationYear,
              zipCode: '',
              country: country || COUNTRY_US_CODE,
              nickName
            }}
            validate={this.validate}
            onSubmit={handleSubmit}
            render={props => {
              const {
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                errors,
                touched,
                isValid,
                values
              } = props;
              const disabledClass = !isValid || loading ? 'disabled ' : '';

              const zipCodeMask =
                values.country === 'US' ? masks.zipCode.us : masks.zipCode.intl;
              return (
                <form
                  onSubmit={handleSubmit}
                  className="form-horizontal"
                  id="edit-credit-card-form"
                >
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label
                        className="control-label required"
                        htmlFor="accountHolderName"
                      >
                        Name on Card
                        {/*                         <span className="asterisk-required"> *</span> */}
                      </label>
                      <Field
                        id="accountHolderName"
                        name="accountHolderName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control text-input-height"
                      />
                      {errors.accountHolderName &&
                        touched.accountHolderName && (
                          <div className="col-xs-12 errorMessage">
                            {errors.accountHolderName}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label
                        className="control-label required"
                        htmlFor="lastFour"
                      >
                        Card Number
                      </label>
                      <Field
                        id="lastFour"
                        name="lastFour"
                        className="form-control text-input-height fs-exclude"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <div
                        className="col-xs-6"
                        style={{ paddingRight: '10px' }}
                      >
                        <div className="col-xs-12">
                          <label
                            className="control-label required"
                            htmlFor="expirationMonth"
                            // style={{ width: '120%' }}
                          >
                            Exp. Month
                            {/*                         <span className="asterisk-required"> *</span> */}
                          </label>
                          <div className="styled-select fs-exclude">
                            <Field
                              id="expirationMonth"
                              name="expirationMonth"
                              component="select"
                              className="cc-form-input small-input text-input-height year-option"
                            >
                              {months.map(i => {
                                return (
                                  <option key={`month${i}`} value={i}>
                                    {i}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                        </div>
                        {errors.expirationMonth && (
                          <div className="col-xs-12 errorMessage">
                            {errors.expirationMonth}
                          </div>
                        )}
                      </div>
                      <div className="col-xs-6" style={{ paddingLeft: '10px' }}>
                        <div className="col-xs-12">
                          <label
                            className="control-label required"
                            htmlFor="expirationYear"
                          >
                            Exp. Year
                            {/*                         <span className="asterisk-required"> *</span> */}
                          </label>
                          <div className="styled-select fs-exclude">
                            <Field
                              id="expirationYear"
                              name="expirationYear"
                              component="select"
                              className="cc-form-input small-input text-input-height "
                            >
                              {years.map(i => {
                                return (
                                  <option key={`year${i}`} value={i}>
                                    {i}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                        </div>
                        {errors.expirationYear && (
                          <div className="col-xs-12 errorMessage">
                            {errors.expirationYear}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label
                        className="control-label required"
                        htmlFor="zipCode"
                      >
                        ZIP Code
                        {/*                         <span className="asterisk-required"> *</span> */}
                      </label>
                      <MaskedInput
                        id="zipCode"
                        name="zipCode"
                        className="form-control text-input-height fs-exclude"
                        mask={zipCodeMask}
                        type="text"
                        value={values.zipCode}
                        guide={false}
                        onBlur={handleBlur}
                        onChange={e => setFieldValue('zipCode', e.target.value)}
                      />
                      {errors.zipCode && touched.zipCode && (
                        <div className="col-xs-12 errorMessage">
                          {errors.zipCode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label className="control-label" htmlFor="cc_country">
                        Country
                        {/*                         <span className="asterisk-required"> *</span> */}
                      </label>
                      <div className="styled-select">
                        <select
                          id="cc_country"
                          className="text-input-height cc-form-input cc-form-input-country"
                          name="country"
                          value={values.country}
                          onChange={e =>
                            setFieldValue('country', e.target.value)
                          }
                        >
                          {this.getCountryList()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <label
                        className="control-label required"
                        htmlFor="nickName"
                      >
                        Payment Nickname
                        {/*                         <span className="asterisk-required"> *</span> */}
                      </label>
                      <Field
                        id="nickName"
                        name="nickName"
                        className="form-control text-input-height remove-halo"
                      />
                      {this.getTooltip('cpn-tt', paymentNameTooltip)}
                      {errors.nickName && touched.nickName && (
                        <div className="col-xs-12 errorMessage">
                          {errors.nickName}
                        </div>
                      )}
                    </div>
                  </div>
                  {this.errorDisplay()}
                  <div
                    className="form-group form-btn"
                    style={{ marginTop: '32px' }}
                  >
                    <div className="col-sm-12 text-center">
                      <button
                        className={`${disabledClass}btn dte-btn`}
                        type="submit"
                        disabled={!isValid || loading}
                        data-track={gtmEditSavePayment.dataTrack}
                        data-track-detail={gtmEditSavePayment.dataTrackDetail}
                        data-track-action={gtmEditSavePayment.dataTrackAction}
                        data-track-sub-action={
                          gtmEditSavePayment.dataTrackSubAction
                        }
                      >
                        {loading ? (
                          <i className="fas fa-spinner fa-spin" />
                        ) : (
                          'Save'
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </Fragment>
    );
  }

  cheque() {
    const { paymentMethod, handleSubmit, loading } = this.props;
    const {
      id,
      kind,
      lastFour,
      accountHolderName,
      nickName,
      routingNumber
    } = paymentMethod;
    return (
      <Fragment>
        <h5 className="text-left payment-method-header heading-color-black margin-bottom-1em">
          <b>Bank Account</b>
        </h5>
        <p className="required-field-text">
          <span className="asterisk-required">*</span> Required Field
        </p>
        <Formik
          initialValues={{
            id,
            type: 'cheque',
            kind,
            lastFour,
            accountHolderName,
            nickName,
            routingNumber
          }}
          validate={this.validate}
          onSubmit={handleSubmit}
          render={props => {
            const {
              handleSubmit,
              handleChange,
              errors,
              touched,
              isValid,
              values
            } = props;
            const disabledClass = !isValid || loading ? 'disabled ' : '';

            return (
              <form
                onSubmit={handleSubmit}
                className="form-horizontal"
                id="edit-bank-account-form"
              >
                <div className="form-group">
                  <div className="col-xs-12 col-md-5 padding-left0">
                    <div className="col-xs-11 padding-right-0">
                      <label
                        className="control-label"
                        htmlFor="accountHolderName"
                      >
                        Name on Bank Account
                        <span className="asterisk-required"> *</span>
                      </label>
                      <Field
                        id="accountHolderName"
                        name="accountHolderName"
                        className="form-control text-input-height"
                      />
                    </div>
                    {errors.accountHolderName && touched.accountHolderName && (
                      <div className="col-xs-12 errorMessage">
                        {errors.accountHolderName}
                      </div>
                    )}
                  </div>
                  <div className="col-xs-12 col-md-5 padding-left0 paddingIcon">
                    <div className="col-xs-11 padding-right-0 bankRadios">
                      <input
                        id="Checking"
                        name="kind"
                        type="radio"
                        value="Checking"
                        checked={values.kind === 'Checking'}
                        onChange={handleChange}
                        className="css-radio"
                        disabled
                      />
                      <label
                        className="css-radio-label"
                        htmlFor="Checking"
                        style={{ paddingRight: '10px' }}
                      >
                        Checking
                      </label>
                      <input
                        id="Savings"
                        name="kind"
                        type="radio"
                        value="Savings"
                        checked={values.kind === 'Savings'}
                        onChange={handleChange}
                        className="css-radio"
                        disabled
                      />
                      <label className="css-radio-label" htmlFor="Savings">
                        Savings
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group routing-number fs-exclude">
                  <div className="col-xs-12 col-md-5 padding-left0">
                    <div className="col-xs-11 padding-right-0">
                      <label className="control-label" htmlFor="routingNumber">
                        Bank Routing Number
                        <span className="asterisk-required"> *</span>
                      </label>
                      <Field
                        id="routingNumber"
                        name="routingNumber"
                        className="form-control text-input-height fs-exclude"
                        disabled
                      />
                    </div>
                    {this.getTooltip('brn-tt', bankRoutingNumberTooltip)}
                  </div>
                </div>
                <div className="form-group account-number fs-exclude">
                  <div className="col-xs-12 col-md-5 padding-left0">
                    <div className="col-xs-11 padding-right-0">
                      <label className="control-label" htmlFor="lastFour">
                        Bank Account Number
                        <span className="asterisk-required"> *</span>
                      </label>
                      <Field
                        id="lastFour"
                        name="lastFour"
                        className="form-control text-input-height fs-exclude"
                        disabled
                      />
                    </div>
                    {this.getTooltip('ban-tt', bankAccountNumberTooltip)}
                  </div>
                  <div className="col-xs-12 col-md-6 padding-left0 paddingIcon">
                    <div className="col-xs-11 padding-right-0">
                      <label
                        className="control-label"
                        htmlFor="lastFour"
                        style={{ width: '130%' }}
                      >
                        Re-Enter Bank Account Number
                        <span className="asterisk-required"> *</span>
                      </label>
                      <Field
                        id="lastFour"
                        name="lastFour"
                        className="form-control text-input-height fs-exclude"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group payment-name-group">
                  <div className="col-xs-12 col-md-5 padding-left0">
                    <div className="col-xs-11 padding-right-0">
                      <label className="control-label" htmlFor="nickName">
                        Payment Nickname
                        <span className="asterisk-required"> *</span>
                      </label>
                      <Field
                        id="nickName"
                        name="nickName"
                        placeholder="Max 45 Characters"
                        className="form-control text-input-height remove-halo"
                      />
                    </div>
                    {this.getTooltip('bpn-tt', paymentNameTooltip)}
                    {errors.nickName && touched.nickName && (
                      <div className="col-xs-12 errorMessage">
                        {errors.nickName}
                      </div>
                    )}
                  </div>
                </div>
                {this.errorDisplay()}
                <div className="form-group">
                  <div
                    className="col-md-12 text-center"
                    style={{ marginTop: '15px' }}
                  >
                    <button
                      className={`${disabledClass}btn dte-btn-md dte-lawn-bkg`}
                      type="submit"
                      disabled={!isValid || loading}
                      style={{ padding: '5px 40px', height: '45px' }}
                    >
                      {loading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Fragment>
    );
  }

  errorDisplay() {
    const { failed } = this.props;
    if (failed && failed.state) {
      return <div className="modal-error-message">{failed.message}</div>;
    }
  }

  render() {
    const { paymentMethod } = this.props;
    const isCreditCard = paymentTypes.card.includes(paymentMethod.kind);
    return (
      <Fragment>
        <a
          href="#"
          onClick={this.handleOpenModal}
          className="payment-action"
          data-track={gtmEditPayment.dataTrack}
          data-track-detail={gtmEditPayment.dataTrackDetail}
          data-track-action={gtmEditPayment.dataTrackAction}
          data-track-sub-action={gtmEditPayment.dataTrackSubAction}
        >
          Edit
        </a>
        <ReactModal
          isOpen={this.state.showModal}
          onAfterOpen={this.enablePopovers}
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          className={
            'modal-dialog modal-lg edit-modal' +
            (isCreditCard ? ' edit-payment-method' : '')
          }
          overlayClassName="modal-backdrop fade in react-modal-overlay"
        >
          <div className="modal-content edit-modal-content">
            <div className="modal-header">
              <button
                className="close"
                type="button"
                onClick={this.handleCloseModal}
              >
                <img className="close-logo-svg" src={closeSvg} alt="Close" />
              </button>
              <h4 className="modal-title">Edit Payment Method</h4>
            </div>
            <div className="modal-body">
              {isCreditCard && this.creditCard()}
              {paymentTypes.cheque.includes(paymentMethod.kind) &&
                this.cheque()}
            </div>
          </div>
        </ReactModal>
      </Fragment>
    );
  }
}

EditPaymentMethod.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  resetError: PropTypes.func
};

export default EditPaymentMethod;
