import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { formatMoney, formatDate } from '../../../../services/utils';
import getTotalAmountDue from './getTotalAmountDue';

const SingleAccountAmountDue = props => {
  const { customerDelinquency, data, isSingleAccount } = props;
  const amountDueText = isSingleAccount ? 'Past Due' : 'Total Amount Due ';

  let totalAmountDue = getTotalAmountDue({ customerDelinquency, data });

  const defaultDueDate = isSingleAccount
    ? moment().format('YYYY-MM-DD')
    : data.defaultDueDate;

  return (
    <div
      className={`collapsed accordion-heading borderBtm`}
      style={{ backgroundColor: '#FFFFFF' }}
    >
      <div className={isSingleAccount ? 'hidden' : 'visible-xs'}>
        <div className="row">
          <div className="col-xs-12">
            <div className="accordion-label singleAmountDue">
              <span className="total_amount_due heading-color-black">
                {amountDueText}
                {defaultDueDate && !isSingleAccount && ' by'}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-7">
            <div className=" accordion-label singleAmountDue total_amount_due">
              {defaultDueDate && !isSingleAccount && formatDate(defaultDueDate)}
            </div>
          </div>
          <div className="col-xs-5 text-right">
            <div className="accordion-value">
              <span id="totalDueAllAccounts" className="currency">
                {formatMoney(totalAmountDue)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={isSingleAccount ? '' : 'hidden-xs'}>
        <div className="row">
          <div className="col-xs-8">
            <div className="accordion-label singleAmountDue">
              <span className="total_amount_due heading-color-black">
                {amountDueText}
                {defaultDueDate &&
                  !isSingleAccount &&
                  ` by ${formatDate(defaultDueDate)}`}
              </span>
            </div>
          </div>
          <div className="col-xs-4 text-right">
            <div className="accordion-value">
              <span id="totalDueAllAccounts" className="currency">
                {formatMoney(totalAmountDue)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SingleAccountAmountDue.propTypes = {
  customerDelinquency: PropTypes.object,
  data: PropTypes.object,
  isSingleAccount: PropTypes.bool
};

export default SingleAccountAmountDue;
