const messages = {
  autoPayDisclaimerText1:
    'This payment method is associated with an account enrolled in AutoPay. To update the expiration date, please edit this payment method. To delete, please',
  autoPayDisclaimerText2: 'unenroll in AutoPay',
  autoPayDisclaimerText3: 'and then remove this payment method.',
  autoPaytext: 'AutoPay',
  bankTextInUpperCase: 'BANK',
  enterPaymentMethod: ' Enter Payment Method'
};
export default messages;
