import React from 'react';
import Loader from 'react-loader-spinner';
import Header from '../../../../containers/Header';

const AppLoader = () => {
  return (
    <div className="app-loader">
      <Header />
      <Loader type="TailSpin" color="#1E3575" height="50" width="50" />
    </div>
  );
};

export default AppLoader;
